import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {NumericFormat} from 'react-number-format';
import {httpClient} from "../../../core/HttpClient";

export default function Accounts() {
    const { t } = useTranslation();
    const [result, setResult] = useState({total: 0, active: 0, totalPlan: {PRO:0,STANDARD:0,BASIC:0,START:0,FREE:0}, activePlan:{PRO:0,STANDARD:0,BASIC:0,START:0,FREE:0}});

    useEffect(() => {
        console.log('[Accounts]');
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/accounts/count';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setResult(res.data);
                }
            });
    }, []);

    return (
        <div>
            <Box display="flex" justifyContent="center" pt={1}>
                <Typography variant="caption" gutterBottom>
                    {t('dashboard.total')}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <Typography variant="h5" gutterBottom color={"primary"}>
                    {`${Intl.NumberFormat().format(result.active)}`}
                </Typography>
                <Typography variant="h5" gutterBottom pl={1} pr={1} color={"grey"}>
                    /
                </Typography>
                <Typography variant="h5" gutterBottom>
                    {`${Intl.NumberFormat().format(result.total)}`}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mb={1}>
                <Typography variant="caption" gutterBottom color={"grey"}>
                    ({(((result.active)/result.total) * 100).toFixed(2)}%)
                </Typography>
            </Box>
            <Divider />
            <Box p={1} display={"flex"} justifyContent={"space-between"}>
                <Box display="flex" justifyContent="center" alignItems={"center"} >
                    <Typography variant="body1" gutterBottom>
                        <b>{t('dashboard.pro')}</b>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h6" gutterBottom color={"primary"}>
                        <NumericFormat value={result.activePlan['PRO']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                    <Typography variant="h6" gutterBottom pl={1} pr={1} color={"grey"}>
                        /
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <NumericFormat value={result.totalPlan['PRO']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box p={1} display={"flex"} justifyContent={"space-between"}>
                <Box display="flex" justifyContent="center" alignItems={"center"} >
                    <Typography variant="body1" gutterBottom>
                        <b>{t('dashboard.standard')}</b>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h6" gutterBottom color={"primary"}>
                        <NumericFormat value={result.activePlan['STANDARD']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                    <Typography variant="h6" gutterBottom pl={1} pr={1} color={"grey"}>
                        /
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <NumericFormat value={result.totalPlan['STANDARD']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box p={1} display={"flex"} justifyContent={"space-between"}>
                <Box display="flex" justifyContent="center" alignItems={"center"} >
                    <Typography variant="body1" gutterBottom>
                        <b>{t('dashboard.basic')}</b>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h6" gutterBottom color={"primary"}>
                        <NumericFormat value={result.activePlan['BASIC']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                    <Typography variant="h6" gutterBottom pl={1} pr={1} color={"grey"}>
                        /
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <NumericFormat value={result.totalPlan['BASIC']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box p={1} display={"flex"} justifyContent={"space-between"}>
                <Box display="flex" justifyContent="center" alignItems={"center"} >
                    <Typography variant="body1" gutterBottom>
                        <b>{t('dashboard.start')}</b>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h6" gutterBottom color={"primary"}>
                        <NumericFormat value={result.activePlan['START']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                    <Typography variant="h6" gutterBottom pl={1} pr={1} color={"grey"}>
                        /
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <NumericFormat value={result.totalPlan['START']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box p={1} display={"flex"} justifyContent={"space-between"}>
                <Box display="flex" justifyContent="center" alignItems={"center"} >
                    <Typography variant="body1" gutterBottom>
                        <b>{t('dashboard.free')}</b>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h6" gutterBottom>
                        <NumericFormat value={result.totalPlan['FREE']} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}