import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import QRCodeStyling from "qr-code-styling";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {FormGroup} from "@mui/material";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_LIFF_URL;
// const CLIENT_BASE_URL = 'https://liff.line.me/1655956966-Pq0O5D4r';
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const qrCode = new QRCodeStyling();

export function QRCode({value, filename='QR_CODE'}) {
    const { t } = useTranslation();
    const [fileExt] = useState("png");
    const [url] = useState(`${CLIENT_BASE_URL}/${value}`);
    const ref = useRef(null);
    let [size, setSize] = useStateWithCallbackLazy(165);
    const [showLogo,setShowLogo] = useState(1);

    let options = {
        "width": size,
        // "width": 358,
        "height": size,
        // "height": 358,
        "margin": size>165?2:6,
        "qrOptions": {"typeNumber": "0", "mode": "Byte", "errorCorrectionLevel": "Q"},
        "imageOptions": {"hideBackgroundDots": true, "imageSize": 0.4, "margin": 1, "crossOrigin": "anonymous"},
        "dotsOptions": {"type": "square", "color": "#000000"},
        "cornersSquareOptions": {"type": (showLogo === 1)?"extra-rounded":"square", "color": "#000000"},
        "cornersSquareOptionsHelper": {
            "colorType": {"single": true, "gradient": false},
            "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}
        },
        "cornersDotOptions": {"type": "", "color": (showLogo === 1)?"#1abc9c":"#000000"},
        "cornersDotOptionsHelper": {
            "colorType": {"single": true, "gradient": false},
            "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}
        },
        "backgroundOptions": {"color": "#ffffff"},
    };
    if(showLogo !== 1){
        options.image = APP_BASE_URL+"/foodkub_white.svg";
    } else {
        options.image = APP_BASE_URL+"/foodkub.svg";
    }
    qrCode._options = options;

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        qrCode.update({
            data: url
        });
    }, [url]);

    const onDownloadClick = (s) => {
        if(s === size){
            qrCode.download({
                name: filename,
                extension: fileExt
            });
        } else {
            setSize(s, ()=>{
                qrCode.download({
                    name: filename,
                    extension: fileExt
                });
            });
        }
    };

    const handleGotoURL = (newPageUrl) => {
        window.open(newPageUrl, "_blank")
    };

    const handleShowLogo = ()=>{
        setShowLogo(showLogo*-1);
    }

    return (<Box display="flex" flexDirection={"column"} justifyContent="center" >
        <div ref={ref} style={{textAlign: 'center'}} />
        <Box m={1}>
            <Box m={1}>
                <Button variant="outlined"
                        disableElevation
                        fullWidth={true}
                        onClick={(e) => handleGotoURL(url)}>
                    {t('common.viewLink')}
                </Button>
            </Box>
            <Box m={1}>
                <Button variant="outlined"
                        disableElevation
                        fullWidth={true}
                        onClick={e=>onDownloadClick(165)}>
                    {t('common.download')} S[165]
                </Button>
            </Box>
            <Box m={1}>
                <Button variant="outlined"
                        disableElevation
                        fullWidth={true}
                        onClick={e=>onDownloadClick(976)}>
                    {t('common.download')} L[976]
                </Button>
            </Box>
            <Box display="flex" justifyContent={"center"}>
                <FormGroup>
                    <FormControlLabel required control={<Checkbox checked={showLogo===1} onClick={handleShowLogo} />} label="Download With Logo" />
                </FormGroup>
            </Box>
        </Box>
    </Box>)
}