import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {Link, useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../core/HttpClient";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import PhoneIcon from "@mui/icons-material/Phone";
import Card from "@mui/material/Card";
import {getPhoneNumber, getUserName} from "../TextFormat";
import {DateI18n} from "../DateI18n";
import DialogContent from "@mui/material/DialogContent";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import {ListItem, ListItemButton} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import BlockIcon from "@mui/icons-material/Block";
import {red} from "@mui/material/colors";
import VerifiedIcon from "@mui/icons-material/Verified";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {getTextI18n, TextI18n} from "../TextI18n";
import {NumericFormat, PatternFormat} from "react-number-format";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

let MyShopAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/s/${value.image.name}`;
    }
    return (<Avatar
        alt={name}
        src={imageUrl}
        variant={"square"}
    />)
};

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <PatternFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

export default function UserInfo() {
    const { t } = useTranslation();
    const {id} = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(0);
    const [customer, setCustomer] = useState({});
    const [verified, setVerified] = React.useState(customer.status==='verified'?true:false);
    const [customerProfile, setCustomerProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [state, setState] = React.useState('');
    const [hits, setHits] = React.useState([]);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/users/' + id;
        const urlInvoice = process.env.REACT_APP_API_BASE_URL + '/admin/users/' + id + '/invoice';

        let tasks = [httpClient.get(url), httpClient.get(urlInvoice)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setCustomer(rs[0].data);
                    setCustomerProfile({firstName: rs[0].data.firstName, lastName: rs[0].data.lastName, mobile: rs[0].data.mobile});
                    setState(rs[0].data.state);
                    if(rs[0].data.status === 'verified'){
                        setVerified(true);
                    } else {
                        setVerified(false);
                    }
                }
                if(rs[1] && rs[1].data){
                    setHits(rs[1].data);
                }
                setLoading(false);
            });
        httpClient.get(url)
            .then(res => {

            });
    },[id]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = (value) => {
        setAnchorEl(null);
        setOpen(value);
    };

    const handleClose = () => {
        setOpen(0);
    };

    const handleStateChange = ({ target }) => {
        setState(target.value);
    };

    const handleVerifyChange = ({ target }) => {
        setVerified(target.checked);
    };

    const handleUpdateStatus = () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/users/' + customer._id + '/state';
        let _data = {
            state: state
        };

        if(verified){
            _data.status = 'verified';
        } else {
            _data.status = 'unverified';
        }

        httpClient.put(url, _data)
            .then(res => {
                setLoading(false);
                setOpen(0);
                setCustomer({...customer, state: res.data.state, status: res.data.status});
            });
    };

    const handleUpdateProfile = () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/users/' + customer._id + '/profile';
        let _data = {...customerProfile};

        httpClient.put(url, _data)
            .then(res => {
                setLoading(false);
                setOpen(0);
                setCustomer({...customer, firstName: customerProfile.firstName,
                    lastName: customerProfile.lastName,
                    mobile: customerProfile.mobile});
            });
    };

    const handleTel = (mobile) => {
        const link = document.createElement('a');
        link.id = 'phone';
        link.href = 'tel:'+mobile;
        link.click();
    }

    const handleOwnerChange = ({ target }) => {
        setCustomerProfile({ ...customerProfile, [target.name]: target.value });
    };

    return(
        <div>
            <Box display="flex" justifyContent="start" alignItems="center" pt={1} pl={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/users" style={{color: '#1abc9c',
                        textDecoration: 'none'}}>
                        <Box display="flex" alignItems="center">
                            <ArrowBackIcon />
                        </Box>
                    </Link>
                </Breadcrumbs>
                <Typography variant="h6" pl={1}>
                    {t('user.info')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <Box p={1}>
                <Card variant="outlined">
                    <CardHeader
                        title={t('user.title')}
                        action={
                        <>
                            <IconButton aria-label="action" onClick={handleMenuClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="control-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={e=>handleOpen(2)}>{t('user.changeProfile')}</MenuItem>
                                <MenuItem onClick={e=>handleOpen(1)}>{t('user.state')}</MenuItem>
                            </Menu>
                        </>
                    }
                    />
                    <CardHeader
                        avatar={
                            <Avatar alt={customer.displayName} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${customer._id}`} />
                        }
                        title={<Typography>{getUserName(customer)}</Typography>}
                        subheader={getPhoneNumber(customer.mobile)}
                        action={<IconButton color={"primary"} edge="start" aria-label="tel" disabled={!customer.mobile} onClick={e=>handleTel(customer.mobile)} >
                            <Avatar sx={customer.mobile?{background: "#1abc9c", width: 32, height: 32 }:{width: 32, height: 32 }}>
                                <PhoneIcon fontSize={"small"} />
                            </Avatar>
                        </IconButton>}
                    />
                    <Box>
                        <Box p={2} pt={0}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1">
                                    {t('user.cif')}
                                </Typography>
                                <Typography variant="subtitle1" style={{color: 'gray'}}>
                                    {customer.cif}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1">
                                    {t('user.register')}
                                </Typography>
                                <Typography variant="subtitle1" style={{color: 'gray'}}>
                                    <DateI18n value={customer.created} format="DD MMM YYYY HH:mm" />
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1">
                                    {t('user.verify')}
                                </Typography>
                                <Typography variant="subtitle1" style={{color: customer.status==='verified'?'green':'red'}}>
                                    {customer.status==='verified'?t('account.verifyPass'):t('account.verifyFail')}
                                </Typography>
                            </Box>
                            {customer.state === 9 &&
                                <Box mt={2}>
                                    <Alert severity="error" >{t('user.ban')}</Alert>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <Box p={2} pb={0}>
                            <Typography variant={"h6"}>
                                {t('user.accounts')}
                            </Typography>
                        </Box>
                        {!loading && customer.accounts.length === 0 &&
                            <Box display="flex" justifyContent="center" p={2} m={2}>
                                <Typography>
                                    {t('common.notFound')}
                                </Typography>
                            </Box>
                        }
                        {!loading && customer.accounts.length > 0 &&
                            <List>
                                {customer.accounts.map((item, index) => (
                                    <ListItemButton key={item.account._id}
                                                    divider={index !== customer.accounts.length - 1} component={Link}
                                                    to={`/accounts/${item.account._id}`}>
                                        <ListItemAvatar>
                                            <MyShopAvatar value={item.account}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.account.name.i18n.en}
                                                      secondaryTypographyProps={{component: 'div'}}
                                                      secondary={
                                                          <>
                                                              <Typography>
                                                                  <DateI18n value={item.account.created}
                                                                            format="DD MMM YYYY HH:mm"/>
                                                                  {' (' + t(`user.roles.${item.roles[0]}`) + ')'}
                                                              </Typography>

                                                          </>
                                                      }/>
                                        <ListItemSecondaryAction>
                                            {item.account.status === 'denied' && <BlockIcon style={{color: red[500]}}/>}
                                            {item.account.status !== 'denied' && item.account.verified === 0 &&
                                                <VerifiedIcon style={{color: 'grey'}}/>}
                                            {item.account.status !== 'denied' && item.account.verified === 6 &&
                                                <ReportProblemIcon style={{color: 'orange'}}/>}
                                            {item.account.status !== 'denied' && item.account.verified === 1 &&
                                                <VerifiedIcon color={"info"}/>}
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                ))}
                            </List>
                        }
                    </Box>
                </Card>
                <Box mt={2}>
                    <Card variant="outlined">
                        <CardHeader
                            title={t('user.orderHistory')}
                        />
                        <Box>
                            {!loading && hits.length === 0 &&
                                <Box display="flex" justifyContent="center" p={2} m={2}>
                                    <Typography>
                                        {t('common.notFound')}
                                    </Typography>
                                </Box>
                            }
                            {!loading && hits.length > 0 &&
                                <List>
                                    {hits.map((hit, index) => (
                                        hit.items.map((item, index) => (
                                            <ListItem key={`key-${index}-${item.uid}`} divider={true}>
                                                <ListItemText primary={
                                                    <Box>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Typography>
                                                                {t('invoice.ref1')}
                                                            </Typography>
                                                            <Typography>
                                                                <strong>{item.ref1}</strong>
                                                            </Typography>
                                                        </Box>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Typography>
                                                                {t('account.title')}
                                                            </Typography>
                                                            <Typography>
                                                                {getTextI18n(item.aname)}
                                                            </Typography>
                                                        </Box>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Typography>
                                                                {t('invoice.payee')}
                                                            </Typography>
                                                            <Typography>
                                                                {item.paymentInfo.payerName}
                                                            </Typography>
                                                        </Box>
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <TextI18n value={item.pname}/>
                                                            <Typography>
                                                                <NumericFormat value={item.amount} displayType={'text'}
                                                                               thousandSeparator={true} prefix={"฿"}/>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                }
                                                              secondaryTypographyProps={{component: 'div'}}
                                                              secondary={
                                                                  <Box display={"flex"} justifyContent={"space-between"}
                                                                       alignItems={"baseline"}>
                                                                      <Typography>
                                                                          <DateI18n value={item.orderDate}
                                                                                    format="DD MMM YYYY HH:mm"/>
                                                                      </Typography>
                                                                      <Typography>
                                                                          {item.state === 'progress' &&
                                                                              <span style={{
                                                                                  color: '#7d7d7d',
                                                                                  fontSize: '1rem'
                                                                              }}>
                                                                            {t('common.progress')}
                                                                        </span>
                                                                          }
                                                                          {item.state === 'completed' &&
                                                                              <span style={{
                                                                                  color: '#7d7d7d',
                                                                                  fontSize: '1rem'
                                                                              }}>
                                                                            {(item.status === 'success') ? t('common.success') : t('common.cancel')}
                                                                        </span>
                                                                          }
                                                                      </Typography>
                                                                  </Box>
                                                              }/>
                                            </ListItem>
                                        ))
                                    ))}
                                </List>
                            }
                        </Box>
                    </Card>
                </Box>
            </Box>
            }
            <Dialog
                open={open>0}
                keepMounted
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {open === 1 &&
                        <Box>
                            <FormLabel component="legend">{t('verification.title')}</FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={verified}
                                        onChange={handleVerifyChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={t('user.verifyPass')}
                            />
                        </Box>
                    }
                    <Box mt={1}>
                        {open === 1 &&
                            <Box>
                                <FormLabel>{t('common.status')}</FormLabel>
                                <RadioGroup aria-label="state" name="state" value={state} onChange={handleStateChange}>
                                    <FormControlLabel value="0" control={<Radio color="primary" />} label={t('common.activate')} />
                                    <FormControlLabel value="9" control={<Radio />} label={t('common.denied')} />
                                </RadioGroup>
                            </Box>
                        }
                        {open === 2 &&
                            <Box>
                                <Box mt={2}>
                                    <TextField id="profile-firstName"
                                               label={t('profile.firstName')}
                                               variant="outlined" fullWidth={true}
                                               name="firstName" value={ customerProfile.firstName }
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               onChange={ handleOwnerChange } required />
                                </Box>
                                <Box mt={2}>
                                    <TextField id="profile-lastName"
                                               label={t('profile.lastName')}
                                               variant="outlined" fullWidth={true}
                                               name="lastName" value={ customerProfile.lastName }
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               onChange={ handleOwnerChange } required />
                                </Box>
                                <Box mt={2} mb={2}>
                                    <TextField id="profile-mobile"
                                               label={t('profile.mobile')}
                                               variant="outlined"
                                               fullWidth={true}
                                               name="mobile" type="tel"
                                               value={customerProfile.mobile}
                                               onChange={ handleOwnerChange }
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               InputProps={{
                                                   inputComponent: PhoneFormatCustom,
                                               }}
                                               required />
                                </Box>
                            </Box>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            color={"default"}
                            onClick={handleClose}
                            fullWidth={true}
                            size="large"
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                    {open === 1 &&
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                disabled={loading}
                                fullWidth={true}
                                onClick={(e) => handleUpdateStatus(e)}>
                            {t('common.confirm')}
                        </Button>
                    }
                    {open === 2 &&
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                disabled={loading
                                    || customerProfile.firstName.trim().length === 0
                                    || customerProfile.lastName.trim().length === 0
                                    || customerProfile.mobile.replace(/\D/g,'').length !== 10
                                    || !customerProfile.mobile.startsWith('0')
                                }
                                fullWidth={true}
                                onClick={(e) => handleUpdateProfile(e)}>
                            {t('common.confirm')}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}