import React from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {TextI18n} from "../../TextI18n";
import {NumericFormat} from "react-number-format";
import {DateI18n} from "../../DateI18n";
import {useEffect} from "react";
import {httpClient} from "../../../core/HttpClient";

export default function AccountInvoice({id}) {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [hits, setHits] = React.useState([]);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + id + '/invoice';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setHits(res.data);
                }
                setLoading(false);
            });
    },[id]);

    return(
        <div>
            <Card variant="outlined">
                <CardHeader
                    title={t('user.orderHistory')}
                />
                <Box>
                    {!loading && hits.length === 0 &&
                        <Box display="flex" justifyContent="center" p={2} m={2}>
                            <Typography>
                                {t('common.notFound')}
                            </Typography>
                        </Box>
                    }
                    {!loading && hits.length > 0 &&
                        <List>
                            {hits.map((hit, index) => (
                                hit.items.map((item, index) => (
                                    <ListItem key={`key-${index}-${item.uid}`} divider={true}>
                                        <ListItemText primary={
                                            <Box>
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Typography>
                                                        {t('invoice.ref1')}
                                                    </Typography>
                                                    <Typography>
                                                        <strong>{item.ref1}</strong>
                                                    </Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <Typography>
                                                        {t('invoice.payee')}
                                                    </Typography>
                                                    <Typography>
                                                        {item.paymentInfo.payerName}
                                                    </Typography>
                                                </Box>
                                                <Box display={"flex"} justifyContent={"space-between"}>
                                                    <TextI18n value={item.pname}/>
                                                    <Typography>
                                                        <NumericFormat value={item.amount} displayType={'text'}
                                                                       thousandSeparator={true} prefix={"฿"}/>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                                      secondaryTypographyProps={{component: 'div'}}
                                                      secondary={
                                                          <Box display={"flex"} justifyContent={"space-between"}
                                                               alignItems={"baseline"}>
                                                              <Typography>
                                                                  <DateI18n value={item.orderDate}
                                                                            format="DD MMM YYYY HH:mm"/>
                                                              </Typography>
                                                              <Typography>
                                                                  {item.state === 'progress' &&
                                                                      <span style={{
                                                                          color: '#7d7d7d',
                                                                          fontSize: '1rem'
                                                                      }}>
                                                                            {t('task.statues.progress')}
                                                                        </span>
                                                                  }
                                                                  {item.state === 'completed' &&
                                                                      <span style={{
                                                                          color: '#7d7d7d',
                                                                          fontSize: '1rem'
                                                                      }}>
                                                                            {(item.status === 'success') ? t('common.success') : t('common.cancel')}
                                                                        </span>
                                                                  }
                                                              </Typography>
                                                          </Box>
                                                      }/>
                                    </ListItem>
                                ))
                            ))}
                        </List>
                    }
                </Box>
            </Card>
        </div>
    )
}