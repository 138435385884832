import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import locale_th from 'dayjs/locale/th';

export function DateI18n({value, format, type}) {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0] || 'en';
    let [display, setDisplay] = useState('');
    if(!value){
        value = new Date();
    }

    useEffect(() => {
        if(type === 'day'){
            let remainingDay = 0;
            let remainingHours = dayjs(value).diff(new Date(), 'hours');
            if(remainingHours > 0){
                remainingDay = Math.ceil(remainingHours / 24);
            }
            remainingDay = remainingDay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setDisplay(remainingDay);
        } else {
            let _format = format || 'DD MMM YYYY';
            if(currentLang === 'th'){
                let newValue = dayjs(value).locale(locale_th).format(_format);
                setDisplay(newValue);
            } else {
                let newValue = dayjs(value).format(_format);
                setDisplay(newValue);
            }
        }
    }, [type, value, currentLang, format]);

    return (<span>
                {type === 'day' &&
                <>
                    {display} {t('pricing.remainingUnit')}
                </>
                }
                {type !== 'day' &&
                <>
                    {display}
                </>
                }
            </span>)
}