import React from 'react';
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {ListItem, ListItemButton, ListItemSecondaryAction} from "@mui/material";
import {getProvinceI18n} from "../../TextI18n";
import {PatternFormat} from "react-number-format";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import {getPhoneNumber, getUserName} from "../../TextFormat";
import {useHistory} from "react-router-dom";

export default function TaskBuyer({value}) {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const handleTel = (mobile) => {
        const link = document.createElement('a');
        link.id = 'phone';
        link.href = 'tel:'+mobile;
        link.click();
    }

    const handleLinkClick= () => {
        if(value.user){
            history.push(`/users/${value.user._id}`);
        }

    }

    return(
        <div>
            <Card variant={"outlined"}>
                <CardHeader
                    title={t('buyer.title')}
                />
                {value && value.customer &&
                    <Box>
                        <List sx={{paddingTop: '0px'}}>
                            <ListItem secondaryAction={
                                <IconButton color={"primary"} edge="end" aria-label="tel" onClick={e=>handleTel(value.customer.mobile)} >
                                    <Avatar sx={{background: "#1abc9c", width: 32, height: 32 }}>
                                        <PhoneIcon fontSize={"small"} />
                                    </Avatar>
                                </IconButton>
                            }>
                                <ListItemButton onClick={handleLinkClick}>
                                    <ListItemAvatar>
                                        <Avatar alt="Profile"
                                                src={(value.customer) ? `${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${value.customer._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={getUserName(value.customer)}
                                                  secondary={getPhoneNumber(value.customer.mobile)}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                }
                {value && value.user &&
                    <Box>
                        <Box>
                            <List sx={{paddingTop: '0px'}}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar alt="Profile"
                                                src={(value.user) ? `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${value.user._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={getUserName(value.user)}
                                                  secondary={getPhoneNumber(value.user.mobile)}
                                    />
                                    {value.user.mobile &&
                                        <ListItemSecondaryAction>
                                            <IconButton color={"primary"} edge="end" aria-label="tel"
                                                        onClick={e => handleTel(value.user.mobile)}>
                                                <Avatar sx={{background: "#1abc9c", width: 32, height: 32}}>
                                                    <PhoneIcon fontSize={"small"}/>
                                                </Avatar>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </List>
                        </Box>
                        {value.address &&
                            <Box p={0}>
                                <Divider/>
                                <Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <Typography gutterBottom variant="h6">
                                            {t('task.deliveryAddress')}
                                        </Typography>
                                    </Box>
                                    <Box p={2}>
                                        <Box border={1} borderRadius={2} borderColor={'primary.main'}
                                             sx={{width: '100%'}} p={1} mb={1}>
                                            <Box>
                                                <Box><b>{value.address.name}</b></Box>
                                                <Box>
                                                    <div style={{'lineHeight': 0.75}}><Typography variant="caption"
                                                                                                  style={{'lineHeight': 1}}>{value.address.addressNumber}</Typography>
                                                    </div>
                                                    <div><Typography
                                                        variant="caption">{getProvinceI18n(value.address.subDistrict, i18n)} {getProvinceI18n(value.address.district, i18n)} {getProvinceI18n(value.address.province, i18n)} {value.address.postalCode}</Typography>
                                                    </div>
                                                    <div><Typography variant="caption">{t('user.tel')} <PatternFormat
                                                        value={value.address.phone} displayType={'text'}
                                                        format="###-###-####"/></Typography></div>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                }
            </Card>
        </div>
    )
}