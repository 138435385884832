import React from 'react';
import { useTranslation } from "react-i18next";

export default function BankInfo({code}) {
    const { t } = useTranslation();

    function SwitchCase(props) {
        switch(props.value) {
            case '002':
                return <span>{t('banks.BBL')}</span>;
            case '004':
                return <span>{t('banks.KBANK')}</span>;
            case '006':
                return <span>{t('banks.KTB')}</span>;
            case '011':
                return <span>{t('banks.TTB')}</span>;
            case '014':
                return <span>{t('banks.SCB')}</span>;
            case '025':
                return <span>{t('banks.BAY')}</span>;
            case '069':
                return <span>{t('banks.KKP')}</span>;
            case '022':
                return <span>{t('banks.CIMBT')}</span>;
            case '067':
                return <span>{t('banks.TISCO')}</span>;
            case '024':
                return <span>{t('banks.UOBT')}</span>;
            case '030':
                return <span>{t('banks.GSB')}</span>;
            case '033':
                return <span>{t('banks.GHB')}</span>;
            case '066':
                return <span>{t('banks.ISBT')}</span>;
            default:
                return <span>{props.value}</span>;
        }
    }

    return(
        <div>
            <SwitchCase value={code} />
        </div>
    )
}