
export function getUserName(user) {
    let name = '';
    if(user){
        if(user.firstName && user.firstName.length > 0){
            name += user.firstName;
            if(user.lastName && user.lastName.length > 0){
                if(name.length>0){
                    name += ' ';
                }
                name += user.lastName;
            }
        }

        if(name.length === 0){
            name = user.displayName;
        }
    }


    return name;
}

export function getPhoneNumber(phoneOrMobile) {
    let result = '';
    if(phoneOrMobile){
        var cleaned = ('' + phoneOrMobile).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '' + match[1] + '-' + match[2] + '-' + match[3];
        }
    }

    return result;
}