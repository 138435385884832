import React, {useEffect} from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddIcon from '@mui/icons-material/Add';
import {useHistory} from "react-router-dom";
import SaleList from "./list/list";


export function Sales() {
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {

    }, []);

    const handleClick = (path) => {
        history.push(path);
    }

    return (<Box>
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" pt={1} pl={1}>
                <Typography variant="h6">
                    {t('sale.title')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            <Box m={1} mt={2}>
                <Button variant="outlined"
                        size={"large"}
                        disableElevation
                        startIcon={<AddIcon />}
                        fullWidth={true}
                        onClick={e=>handleClick("/sales/new")}>
                    {t('sale.add')}
                </Button>
            </Box>
            <Box>
                <SaleList />
            </Box>
        </Box>
    </Box>)
}