import React, {useState, useEffect, useRef, useReducer} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import InfiniteScroll from 'react-infinite-scroll-component';
import {DateI18n} from "../DateI18n";
import BankInfo from "../BankInfo";
import {httpClient} from "../../core/HttpClient";
import _ from 'lodash';
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import SearchIcon from "@mui/icons-material/Search";
import {getTextI18n} from "../TextI18n";
import {NumericFormat} from "react-number-format";

let controller = null;
const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch) {
    console.log('fetchHits');
    dispatch({ type: "FETCH_START" });
    try {
        const signal = controller.signal;
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/invoices';
        const result = await httpClient(
            `${url}?q=${query.search}&c=${query.cursor}`,
            { signal }
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data, cursor: query.cursor })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

let MyItemTitle = ({value, t}) => {
    return (<>
        <Box>
            <Typography variant="body1" style={{'fontWeight': 'bold'}}>
                {getTextI18n(value.pname)}
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.account')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                {getTextI18n(value.aname)}
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.qty')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                {value.qty} {t('invoice.unit')}
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.price')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                <NumericFormat value={value.paymentInfo.amount} displayType={'text'} thousandSeparator={true} prefix={"฿"} />
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.ref1')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                {value.paymentInfo.billPaymentRef1}
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.ref2')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                {value.paymentInfo.billPaymentRef2}
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.payee')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                {value.paymentInfo.payerName}
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.bank')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                <BankInfo code={value.paymentInfo.sendingBankCode} />
            </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">
                {t('invoice.payDate')}
            </Typography>
            <Typography variant="subtitle1" style={{color: 'gray'}}>
                <DateI18n value={value.paymentInfo.transactionDateandTime} format="DD MMM YYYY HH:mm" />
            </Typography>
        </Box>
    </>)
};

export default function Invoices() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [query, setQuery] = useState({cursor: '', search: ''});
    const [{ hits, hasNext, hasError, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null
    });

    const debouncedFetchHits = useRef(
        _.debounce(
            (query) => fetchHits(query, dispatch),
            800
        )
    ).current;

    useEffect(() => {
        console.log('[Invoices]');
        controller = new AbortController();
        const timeOutId = setTimeout(() => debouncedFetchHits({cursor: '', search: ''}), 10);
        return () => clearTimeout(timeOutId);
    }, [debouncedFetchHits]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData', cursor);
        let newValue = {...query, cursor: cursor};
        setQuery(newValue);
        const cancelToken = httpClient.CancelToken.source();
        debouncedFetchHits(newValue, cancelToken.token).then(r=>{});
    };

    const handleQueryChange = (value) =>{
        setQuery({cursor: '', search: value});
        if(controller){
            controller.abort();
            controller = new AbortController();
        } else {
            controller = new AbortController();
        }
        debouncedFetchHits({cursor: '', search: value}).then(r => {});
    };

    return (
        <div>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <Typography variant="h6">
                            {t('invoice.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={t('invoice.searchExample')}
                                fullWidth={true}
                                className={classes.inputInput}
                                style={{
                                    border: '1px solid gainsboro',
                                    borderRadius: '5px',
                                    paddingLeft: `calc(1em + 18px)`,
                                }}
                                value={query.search}
                                onChange={event => handleQueryChange(event.target.value)}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {hasError &&
                <Box m={2}><Alert severity="error">{t('common.message.error')}</Alert></Box>
            }
            {!isLoading && hits.length === 0 && !hasError &&
                <Box display="flex" justifyContent="center" p={2} m={2}>
                    <Typography>
                        {t('common.notFound')}
                    </Typography>
                </Box>
            }
            <InfiniteScroll
                dataLength={hits.length} //This is important field to render the next data
                next={e=>handleFetchData(cursor)}
                hasMore={hasNext}
            >
                <List>
                    {hits.map((hit, index) => (
                        hit.invoices.map((value, index) => (
                        <ListItem key={''+index+'_'+value._id} divider={index !== hits.length - 1}>
                            <ListItemText primary={<MyItemTitle value={value} t={t} />} />
                        </ListItem>
                        ))
                    ))}
                </List>
            </InfiniteScroll>
            {isLoading &&
            <Box display="flex" justifyContent="center" mt={1} mb={1}>
                <CircularProgress size={20}/>
            </Box>
            }
        </div>
    )
}