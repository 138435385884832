import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {Route, Redirect, useHistory} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import jwt_decode from "jwt-decode";
import FooterMenu from "../../components/FooterMenu";
import { Context } from "../../core/Context";
import Box from "@mui/material/Box";

const DEFAULT_PROFILE_PICTURE = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.svg`;

const Console = ({role, children}) => {
    const { t } = useTranslation();
    const [context, setContext] = useState({displayName: '', role: role, pictureUrl: DEFAULT_PROFILE_PICTURE, success: false, fail: false});
    const [loading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const open = Boolean(anchorEl);

    useEffect(() => {
        let token = localStorage.getItem('admin-token');
        if(!token){
            history.push('/signin');
        }

        let displayName = localStorage.getItem('displayName');
        let pictureUrl = localStorage.getItem('pictureUrl');
        setContext({displayName: displayName, pictureUrl: pictureUrl})
    }, [history]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event) => {
        localStorage.clear();
        window.location.href = '/login';
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setContext({ ...context, success: false, fail: false });
    };

    return (
        <div className='page page-dashboard'>
            <CssBaseline />
            <header>
                <AppBar elevation={0} position="static">
                    <Toolbar>
                        <Typography variant="h6" noWrap={true} component="div" sx={{ flexGrow: 1 }}>
                            <span>{context.displayName}</span>
                        </Typography>

                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar alt={context.displayName} src={context.pictureUrl} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem dense onClick={handleLogout}>{t('logout')}</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </header>
            <div>
                <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
            </div>
            <Box style={{height: '75px'}}></Box>
            <FooterMenu />
            <Snackbar open={context.success}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t('common.message.saveSuccess')}
                </Alert>
            </Snackbar>
            <Snackbar open={context.fail}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {t('common.message.saveFail')}
                </Alert>
            </Snackbar>
            <Backdrop
                style={{
                    zIndex: 999,
                    color: '#fff',
                }}
                open={loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </div>
    );
};

const ConsoleLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const token = localStorage.getItem('admin-token');
                if (token) {
                    var decoded = jwt_decode(token);
                    if(decoded.isAdmin){
                        return <Console role={decoded.role} ><Component {...props} /></Console>
                    }else{
                        return <Redirect to={
                            {
                                pathname: '/login',
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }
                } else {
                    return <Redirect to={
                        {
                            pathname: '/login',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }}
        />
    );
};

export default ConsoleLayoutRoute;