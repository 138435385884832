import React, {useEffect} from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {useHistory} from "react-router-dom";

export function MoreMenu() {
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {

    }, []);

    const handleClick = (path) => {
        history.push(path);
    }

    return (<Box>
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" pt={1} pl={1}>
                <Typography variant="h6">
                    {t('more.title')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            {/*<Box m={1} mt={2}>*/}
            {/*    <Button variant="outlined"*/}
            {/*            size={"large"}*/}
            {/*            disableElevation*/}
            {/*            startIcon={<CrisisAlertIcon />}*/}
            {/*            fullWidth={true}*/}
            {/*            onClick={e=>handleClick("/sales")}>*/}
            {/*        {t('sale.title')}*/}
            {/*    </Button>*/}
            {/*</Box>*/}
            <Box m={1}>
                <Button variant="outlined"
                        size={"large"}
                        disableElevation
                        startIcon={<ReceiptLongIcon />}
                        fullWidth={true}
                        onClick={e=>handleClick("/invoices")}>
                    {t('invoice.title')}
                </Button>
            </Box>
        </Box>
    </Box>)
}