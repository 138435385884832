import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import locale_th from 'dayjs/locale/th';
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);

export function TimeFromNow(props) {
    const { i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0] || 'en';
    let [display, setDisplay] = useState('');
    useEffect(() => {
        console.log('TimeFromNow');
        if(currentLang === 'th'){
            let newValue = dayjs(props.value).locale(locale_th).fromNow();
            setDisplay(newValue);
        } else {
            let newValue = dayjs(props.value).fromNow();
            setDisplay(newValue);
        }

    }, [props, currentLang, setDisplay]);

    return(
        <>
        {props.value &&
            <span>
                {display}
            </span>
        }
        </>
    )
}