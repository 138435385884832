import React, {useState, useEffect, useReducer, useRef} from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VerifiedIcon from '@mui/icons-material/Verified';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import {httpClient} from "../../core/HttpClient";
import {getPhoneNumber, getUserName} from "../TextFormat";
import Grid from "@mui/material/Grid";
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {DateI18n} from "../DateI18n";

let controller = null;
const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch) {
    console.log('fetchHits');
    dispatch({ type: "FETCH_START" });
    try {
        const signal = controller.signal;
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/users';
        const result = await httpClient(
            `${url}?q=${query.search}&c=${query.cursor}`,
            { signal }
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data, cursor: query.cursor })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${value._id}?dt=`+new Date().getTime();
    return (<Avatar
        alt={value.displayName}
        src={imageUrl}
    />)
};

export default function Users() {
    const { t } = useTranslation();
    const [{ hits, hasNext, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null
    });
    const [query, setQuery] = useState({cursor: '', search: ''});
    const classes = useStyles();

    const debouncedFetchHits = useRef(
        _.debounce(
            (query) => fetchHits(query, dispatch),
            800
        )
    ).current;

    useEffect(() => {
        console.log('[Users]');
        controller = new AbortController();
        const timeOutId = setTimeout(() => debouncedFetchHits({cursor: '', search: ''}), 10);
        return () => clearTimeout(timeOutId);
    }, [debouncedFetchHits]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData', cursor);
        let newValue = {...query, cursor: cursor};
        setQuery(newValue);
        const cancelToken = httpClient.CancelToken.source();
        debouncedFetchHits(newValue, cancelToken.token).then(r=>{});
    };

    const handleQueryChange = (value) =>{
        setQuery({cursor: '', search: value});
        if(controller){
            controller.abort();
            controller = new AbortController();
        } else {
            controller = new AbortController();
        }
        debouncedFetchHits({cursor: '', search: value}).then(r => {});
    };

    return (
        <div>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <Typography variant="h6">
                            {t('user.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={t('user.searchExample')}
                                fullWidth={true}
                                className={classes.inputInput}
                                style={{
                                    border: '1px solid gainsboro',
                                    borderRadius: '5px',
                                    paddingLeft: `calc(1em + 18px)`,
                                }}
                                value={query.search}
                                onChange={event => handleQueryChange(event.target.value)}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box>
                {hits.length > 0 &&
                    <InfiniteScroll
                        dataLength={hits.length} //This is important field to render the next data
                        next={e => handleFetchData(cursor)}
                        hasMore={hasNext}
                    >
                        <List>
                            {hits.map((value, index) => (
                                <ListItem key={value._id} divider={index !== hits.length - 1} button
                                          component={Link} to={`/users/${value._id}`}>
                                    <ListItemAvatar>
                                        <MyAvatar value={value}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={<Box display={"flex"} alignItems={"center"}>
                                        <Box mr={1}>
                                            <Typography>
                                                {getUserName(value)}
                                            </Typography>
                                        </Box>
                                        {value.status === 'verified' && <VerifiedIcon color={"info"} sx={{fontSize: 16}} />}
                                        {value.status !== 'verified' && value.state === 0 &&
                                            <VerifiedIcon style={{color: 'grey'}}  sx={{fontSize: 16}} />}
                                        {value.status !== 'verified' && value.state === 6 &&
                                            <ReportProblemIcon style={{color: 'orange'}}  sx={{fontSize: 16}} />}
                                    </Box>
                                    }
                                                  secondaryTypographyProps={{component: 'div'}}
                                                  secondary={<Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
                                                      <Typography>
                                                          {getPhoneNumber(value.mobile)}
                                                      </Typography>
                                                      <Typography>
                                                          <DateI18n value={value.created} format="DD MMM YYYY HH:mm" />
                                                      </Typography>
                                                  </Box>} />
                                </ListItem>
                            ))}
                        </List>
                    </InfiniteScroll>
                }
            </Box>
            {isLoading &&
                <Box display="flex" justifyContent="center" mt={4} mb={4} pb={2}>
                    <CircularProgress size={20}/>
                </Box>
            }
        </div>
    )
}