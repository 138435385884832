import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectorOptions = {
    order: ['localStorage']
};

i18n
// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
//     .use(Backend)
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        resources: {
            th: {
                translations: require('./locales/th/translations.json')
            },
            en: {
                translations: require('./locales/en/translations.json')
            }
        },
        load: 'languageOnly',
        fallbackLng: 'th-TH',
        ns: ['translations'],
        defaultNS: 'translations',
        detection: detectorOptions,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
            useSuspense: false,
        },
    });


export default i18n;