import React, {useState, useEffect, useReducer, useRef} from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import {httpClient} from "../../../core/HttpClient";
import {getPhoneNumber} from "../../TextFormat";
import Grid from "@mui/material/Grid";
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {DateI18n} from "../../DateI18n";
import SaleChart from "../chart/list";
import {NumericFormat} from "react-number-format";

let controller = null;
let lat = 13.673838;
let lng = 100.539813;

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                // hasNext: action.payload.length > 0,
                hasNext: false, // Does not implement pagination yet.
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch) {
    console.log('fetchHits');
    dispatch({ type: "FETCH_START" });
    try {
        const signal = controller.signal;
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/sale';
        const result = await httpClient(
            `${url}?q=${query.search}&c=${query.cursor}&lat=${lat}&lng=${lng}`,
            { signal }
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data, cursor: query.cursor })

    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo_16_9.png`;
    return (<Avatar
        alt={value.name}
        src={imageUrl}
        variant={"square"}
    />)
};

export default function SaleList() {
    const { t } = useTranslation();
    const [{ hits, hasNext, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null
    });
    const [query, setQuery] = useState({cursor: '', search: ''});
    const classes = useStyles();

    const debouncedFetchHits = useRef(
        _.debounce(
            (query) => fetchHits(query, dispatch),
            800
        )
    ).current;

    useEffect(() => {
        console.log('[SaleList]');
        controller = new AbortController();
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                if(position && position.coords){
                    lat = position.coords.latitude;
                    lng = position.coords.longitude;
                    const timeOutId = setTimeout(() => debouncedFetchHits({cursor: '', search: ''}), 10);
                    return () => clearTimeout(timeOutId);
                }
            }, (error) => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }
    }, [debouncedFetchHits]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData', cursor);
        let newValue = {...query, cursor: cursor};
        setQuery(newValue);
        const cancelToken = httpClient.CancelToken.source();
        debouncedFetchHits(newValue, cancelToken.token).then(r=>{});
    };

    const handleQueryChange = (value) =>{
        setQuery({cursor: '', search: value});
        if(controller){
            controller.abort();
            controller = new AbortController();
        } else {
            controller = new AbortController();
        }
        debouncedFetchHits({cursor: '', search: value}).then(r => {});
    };

    return (
        <div>
            {!isLoading && hits.length === 0 &&
                <Box display="flex" justifyContent="center" p={2} m={2}>
                    <Typography>
                        {t('common.notFound')}
                    </Typography>
                </Box>
            }
            {!isLoading && hits.length > 0 &&
                <>
                    <Box m={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box m={4} mt={3} mb={3} display={"flex"} justifyContent={"center"}>
                                <SaleChart />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={t('sale.searchExample')}
                                    fullWidth={true}
                                    className={classes.inputInput}
                                    style={{
                                        border: '1px solid gainsboro',
                                        borderRadius: '5px',
                                        paddingLeft: `calc(1em + 18px)`,
                                    }}
                                    value={query.search}
                                    onChange={event => handleQueryChange(event.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                <Box>
                    {hits.length > 0 &&
                        <InfiniteScroll
                            dataLength={hits.length} //This is important field to render the next data
                            next={e => handleFetchData(cursor)}
                            hasMore={hasNext}
                        >
                            <List>
                                {hits.map((value, index) => (
                                    <ListItem key={value._id} divider={index !== hits.length - 1} button
                                              component={Link} to={`/sales/${value._id}`}>
                                        <ListItemAvatar>
                                            <MyAvatar value={value}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Box mr={1}>
                                                <Box>
                                                    <Typography variant={"body1"}>
                                                        {value.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography variant={"caption"}>
                                                    <NumericFormat value={value.dist.calculated} displayType={'text'} thousandSeparator={true} decimalScale={2} /> km.
                                                </Typography>
                                            </Box>
                                        </Box>
                                        }
                                      secondaryTypographyProps={{component: 'div'}}
                                      secondary={<Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
                                          <Typography>
                                              {getPhoneNumber(value.mobile)}
                                          </Typography>
                                          <Typography>
                                              <DateI18n value={value.created} format="DD MMM YYYY HH:mm" />
                                          </Typography>
                                      </Box>} />
                                    </ListItem>
                                ))}
                            </List>
                        </InfiniteScroll>
                    }
                </Box>
            </>
            }
            {isLoading &&
                <Box display="flex" justifyContent="center" mt={4} mb={4} pb={2}>
                    <CircularProgress size={20}/>
                </Box>
            }
        </div>
    )
}