import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import Box from "@mui/material/Box";
import {httpClient} from "../../../core/HttpClient";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {DocumentsUpload} from "../../DocumentsUpload";
import Divider from "@mui/material/Divider";
import VerifiedIcon from '@mui/icons-material/Verified';
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import { PatternFormat } from 'react-number-format';
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const MemoizedImagesUpload = React.memo(({account, value, onChange})=>{
    return(<DocumentsUpload account={account} value={value} onChange={ onChange } />)
});

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <PatternFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

export default function VerifyByAdminDialog({verification, account, open, onClose}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [actor, setActor] = useState('');
    const [owner, setOwner] = useState({firstName:'', lastName: '', mobile: '', status: 'unverified'});
    const [moreDocuments, setMoreDocuments] = useState([]);

    useEffect(() => {

        if(account && account.owner){
            setOwner(account.owner);
        }

    }, [account]);

    const handleClose = ()=>{
        onClose(false);
    };

    const handleUploadMoreDocumentChange = ({ target }) => {
        if(target && target.value && target.value[0]){
            let _documents = target.value;
            setMoreDocuments(_documents);
        }
    };

    const handleSubmit = ()=>{
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + `/admin/verify-by-admin`;
        httpClient.post(url, {
            actor: actor,
            moreDocuments: moreDocuments,
            accountId: account._id
        }).then(res => {
            onClose(true);
        });
    };

    const handleOwnerChange = ({ target }) => {
        setOwner({ ...owner, [target.name]: target.value });
    };

    const handleActorChange = ({ target }) => {
        setActor(target.value);
    };

    return (
        <>
        <Dialog keepMounted
                fullWidth={true}
                open={open}
                onClose={handleClose}>
            <DialogContent>
                <Box>
                    <Box mt={2}>
                        <TextField id="profile-firstName"
                                   label={t('profile.firstName')}
                                   variant="outlined" fullWidth={true}
                                   name="firstName" value={ owner.firstName }
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   InputProps={{
                                       readOnly: owner.status !== 'unverified',
                                       endAdornment: owner.status !== 'unverified'?(
                                           <InputAdornment position="end">
                                               <VerifiedIcon color={owner.status === 'verified'?"info":"default"} />
                                           </InputAdornment>
                                       ):null,
                                   }}
                                   onChange={ handleOwnerChange } required />
                    </Box>
                    <Box mt={2}>
                        <TextField id="profile-lastName"
                                   label={t('profile.lastName')}
                                   variant="outlined" fullWidth={true}
                                   name="lastName" value={ owner.lastName }
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   InputProps={{
                                       readOnly: owner.status !== 'unverified',
                                       endAdornment: owner.status !== 'unverified'?(
                                           <InputAdornment position="end">
                                               <VerifiedIcon color={owner.status === 'verified'?"info":"default"} />
                                           </InputAdornment>
                                       ):null,
                                   }}
                                   onChange={ handleOwnerChange } required />
                    </Box>
                    <Box mt={2} mb={2}>
                        <TextField id="profile-mobile"
                                   label={t('profile.mobile')}
                                   variant="outlined"
                                   fullWidth={true}
                                   name="mobile" type="tel"
                                   value={owner.mobile}
                                   onChange={ handleOwnerChange }
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   InputProps={{
                                       inputComponent: PhoneFormatCustom,
                                       readOnly: owner.status !== 'unverified',
                                       endAdornment: owner.status !== 'unverified'?(
                                           <InputAdornment position="end">
                                               <VerifiedIcon color={owner.status === 'verified'?"info":"default"} />
                                           </InputAdornment>
                                       ):null,
                                   }}
                                   required />
                    </Box>
                    <Box mt={2}>
                        <FormControl fullWidth variant="outlined">
                            <fieldset style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                                paddingTop: '0px',
                                borderRadius: '4px',
                                fontSize: '12px',
                                color: 'rgba(0, 0, 0, 0.6)'
                            }}>
                                <legend>{t('account.actor.title')}*</legend>
                                <Box>
                                    <RadioGroup
                                        aria-labelledby="ordering"
                                        name="actor"
                                        value={actor}
                                        onChange={handleActorChange}
                                    >
                                        <FormControlLabel value="OWNER" control={<Radio />} label={t('account.actor.owner')} />
                                        <FormControlLabel value="DEALER" control={<Radio />} label={t('account.actor.dealer')} />
                                    </RadioGroup>
                                </Box>
                            </fieldset>
                        </FormControl>
                    </Box>
                </Box>
                <Box mb={2} mt={2}>
                    <Divider />
                </Box>
                <Box mt={2}>
                    <MemoizedImagesUpload account={account} value={moreDocuments} onChange={handleUploadMoreDocumentChange} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                        color={"default"}
                        onClick={handleClose}
                        fullWidth={true}
                        size="large"
                        disabled={loading}
                        disableElevation>
                    {t('common.close')}
                </Button>
                <Button variant="contained"
                        color={"primary"}
                        disabled={loading
                            || actor.length === 0
                            || owner.firstName.length === 0
                            || owner.lastName.length === 0
                            || (owner.mobile.length === 0 || !owner.mobile.startsWith("0"))
                            || moreDocuments.length === 0
                        }
                        onClick={handleSubmit}
                        fullWidth={true}
                        size="large"
                        disableElevation>
                    {t('common.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
};