import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { NumericFormat } from 'react-number-format';
import {httpClient} from "../../../core/HttpClient";

export default function Invoices() {
    const { t } = useTranslation();
    const [result, setResult] = useState({total: 0, today:0, month: 0, previousMonth: 0, year: 0, previousYear: 0});

    useEffect(() => {
        console.log('[Invoices]');
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/invoices/summary';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setResult(res.data);
                }
            });
    }, []);

    return (
        <Box>
            <Box display="flex" justifyContent="center" pt={1}>
                <Typography variant="caption" gutterBottom>
                    {t('dashboard.income')}{t('dashboard.today')}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <Typography variant="h4" gutterBottom color="primary">
                    <NumericFormat value={result.today} displayType={'text'} thousandSeparator={true} decimalScale={0} />
                </Typography>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between">
                <Box width={1/2}>
                    <Box display="flex" justifyContent="center" pt={1}>
                        <Typography variant="caption" gutterBottom>
                            {t('dashboard.toMonth')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" gutterBottom>
                            <NumericFormat value={result.month} displayType={'text'} thousandSeparator={true} decimalScale={0} />
                        </Typography>
                    </Box>
                </Box>
                <Box width={1/2}>
                    <Box display="flex" justifyContent="center" pt={1}>
                        <Typography variant="caption" gutterBottom>
                            {t('dashboard.previousMonth')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" gutterBottom>
                            <NumericFormat value={result.previousMonth} displayType={'text'} thousandSeparator={true} />
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between">
                <Box width={1/2}>
                    <Box display="flex" justifyContent="center" pt={1}>
                        <Typography variant="caption" gutterBottom>
                            {t('dashboard.toYear')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" gutterBottom>
                            <NumericFormat value={result.year} displayType={'text'} thousandSeparator={true} decimalScale={0} />
                        </Typography>
                    </Box>
                </Box>
                <Box width={1/2}>
                    <Box display="flex" justifyContent="center" pt={1}>
                        <Typography variant="caption" gutterBottom>
                            {t('dashboard.previousYear')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h6" gutterBottom>
                            <NumericFormat value={result.previousYear} displayType={'text'} thousandSeparator={true} />
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}