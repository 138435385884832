import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import Divider from "@mui/material/Divider";
import {Link, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TextField from "@mui/material/TextField";
import {ImageUploads} from "../../ImageUploads";
import Button from "@mui/material/Button";
import {MapLocation} from "../../MapLocation";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {Paper} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {httpClient} from "../../../core/HttpClient";
import {PatternFormat} from "react-number-format";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const MemoizedImages = React.memo(({name, value, onChange})=>{
    return(<ImageUploads name={name} images={ value } aspect={1/1} onChange={ onChange } />)
});

const MemoizedMapContainer = React.memo(({position, callback})=>{
    return(<MapLocation position={position} callback={callback} />);
});

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <PatternFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

export function SaleInfo() {
    const { t } = useTranslation();
    const {id} = useParams();
    const [sale, setSale] = useState({_id: '', name: '', mobile: '', contact: '', addressNote: '', images: [], standee: false
        , closeSale: 'YES', lat: 13.673838, lng: 100.539813, remark: ''});
    const [error, setError] = useState({});
    const [updated, setUpdated] = useState(false);
    const [position, setPosition] = useState(null);

    useEffect(() => {
        console.log('[SaleInfo]');
        if(id === 'new'){
            setPosition({lat: 13.673838, lng: 100.539813});
        } else {
            const url = process.env.REACT_APP_API_BASE_URL + '/admin/sale/' + id;
            httpClient.get(url)
                .then(res => {
                    if (res.data) {
                        setSale(res.data);
                        if(res.data.lat){
                            setPosition({lat: res.data.lat, lng: res.data.lng});
                        }
                    }
                });
        }


    }, [id]);

    const handleSave = () => {
        console.log(sale);
        let _error = {};
        if(sale.name.trim().length === 0){
            _error.name = true;
        }
        if(sale.closeSale === 'YES'){
            if(sale.standee){
                if(!sale.addressNote){
                    _error.addressNote = true;
                }
            }

        }
        setError(_error);
        let keys = Object.keys(_error);
        if(keys.length === 0){
            const url = process.env.REACT_APP_API_BASE_URL + `/admin/sale`;
            httpClient.post(url, {...sale})
                .then(res => {
                    if(res.data && res.data._id){
                        setSale({...res.data});
                        setUpdated(true);
                    }
                });
        }
    };

    const handleTextChange = ({ target }) => {
        setSale({ ...sale, [target.name]: target.value });
    };

    const handleImageChange = ({ target }) => {
        setSale({ ...sale, [target.name]: target.value });
    };

    const handleRadioChange = ({ target }) => {
        setSale({ ...sale, [target.name]: target.value });
    };

    const handleCheckChange = ({ target }) => {
        setSale({ ...sale, [target.name]: target.checked });
    };

    const handleMapChange = ({ lat, lng }) => {
        setPosition({lat: lat, lng: lng});
        setSale({ ...sale, lat: lat, lng: lng });
    };

    const handleCloseMessage = () => {
        setUpdated(false);
    };

    return (<Box>
        <Box>
            <Box display="flex" justifyContent="start" alignItems="center" pt={1} pl={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/sales" style={{color: '#1abc9c',
                        textDecoration: 'none'}}>
                        <Box display="flex" alignItems="center">
                            <ArrowBackIcon />
                        </Box>
                    </Link>
                </Breadcrumbs>
                <Typography variant="h6" pl={1}>
                    {t('sale.info')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            <Box m={1} mt={2}>
                <Box>
                    <MemoizedMapContainer position={position} callback={handleMapChange} />
                </Box>
                <Box mt={2}>
                    <TextField value={sale.name}
                               name={"name"}
                               label={t('sale.shopName')}
                               variant="outlined"
                               fullWidth={true}
                               error={error.name}
                               onChange={handleTextChange} />
                </Box>
                <Box mt={2}>
                    <TextField id="shopMobile"
                               label={t('sale.shopMobile')}
                               variant="outlined"
                               fullWidth={true}
                               name="mobile" type="tel"
                               value={sale.mobile}
                               onChange={ handleTextChange }
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               InputProps={{
                                   inputComponent: PhoneFormatCustom
                               }}
                               required />
                </Box>
                <Box mt={2}>
                    <TextField value={sale.contact} name={"contact"} label={t('sale.shopContactPoint')} variant="outlined" fullWidth={true} onChange={handleTextChange} />
                </Box>
                <Box mt={2}>
                    <TextField label={t('sale.shopAddress')}
                               value={sale.addressNote}
                               name={"addressNote"}
                               variant="outlined"
                               error={error.addressNote}
                               onChange={handleTextChange}
                               multiline={true}
                               minRows={3}
                               fullWidth={true} />
                </Box>
                <Box mt={2}>
                    <Paper elevation={0} style={{backgroundColor: '#f4f4f4'}}>
                        <Box p={3} display={"flex"} justifyContent={"center"}>
                            <Typography variant={"caption"}>{t('sale.imageNotFound')}</Typography>
                        </Box>
                    </Paper>
                </Box>
                <Box mt={2} mb={2} display="flex" justifyContent="center" alignItems="center">
                    <MemoizedImages name="image" values={ sale.images } onChange={ handleImageChange } />
                </Box>
                <Box mt={2}>
                    <TextField name={"remark"} value={sale.remark} label={t('sale.remark')} variant="outlined" multiline={true} minRows={3} fullWidth={true} onChange={handleTextChange} />
                </Box>
                <Divider />
                <Box m={2} >
                    <FormControl>
                        <FormLabel><strong>{t('sale.closeSaleStatus')}</strong></FormLabel>
                        <RadioGroup
                            aria-labelledby="result-group-label"
                            defaultValue="yes"
                            name="closeSale"
                            value={sale.closeSale}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value="YES" control={<Radio />} label={t('sale.closeSaleYes')} />
                            <FormControlLabel value="NO" control={<Radio />} label={t('sale.closeSaleNo')} />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box m={2} >
                    <FormControlLabel control={
                        <Checkbox
                            name="standee"
                            checked={sale.standee}
                            value={sale.standee}
                            onClick={handleCheckChange}
                            disabled={sale.closeSale === 'NO'} />
                    }
                      label={t('sale.standee')} />
                </Box>
                <Box mt={4} mb={4}>
                    <Button variant={"contained"} color={"primary"} fullWidth={true} size={"large"} disableElevation={true} onClick={handleSave} >
                        {t('common.save')}
                    </Button>
                </Box>
            </Box>
        </Box>
        <Snackbar open={updated}
                  anchorOrigin={{vertical:'top', horizontal:'center'}}
                  autoHideDuration={1500} onClose={handleCloseMessage}>
            <Alert onClose={handleCloseMessage} severity="success">
                {t('common.message.saveSuccess')}
            </Alert>
        </Snackbar>
    </Box>)
}