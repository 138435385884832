import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import {TimeFromNow} from "../TimeFromNow";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

export function LastActive({value}) {
    const { t } = useTranslation();
    let [status, setStatus] = useState('offline');
    useEffect(() => {
        console.log('[LastActive]');
        if(value){
            if(value === 1 || dayjs().diff(value, 'minute', true) < 120){
                setStatus('online');
            }
        }
    }, [value]);

    return(
        <Typography variant="caption">
            <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                <CircleIcon sx={{fontSize: 12}} color={status==='online'?'success':'secondary'} />
                {value === 1 &&
                    <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                        {t('shop.online')}
                    </Box>
                }
                {!value &&
                    <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                        {t('common.notFound')}
                    </Box>
                }
                {value > 1 &&
                    <>
                        <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                            {t('shop.lastActive')}
                        </Box>
                        <TimeFromNow value={value} />
                    </>
                }
            </Box>
        </Typography>
    )
}