import React, {useEffect, useRef} from "react";
import {MapContainer, Marker, TileLayer, useMap, useMapEvents} from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css'
import './style.css'

const redIcon = new L.Icon({
    iconUrl:
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
    iconSize: [21, 34],
    iconAnchor: [10, 34]
});

const LocationMarker = (props) => {
    console.log('LocationMarker', props.position);
    const map = useMap();
    const markerRef = useRef(null);

        useMapEvents({
            move() {
                const marker = markerRef.current;
                if (marker != null) {
                    marker.setLatLng(map.getCenter());
                }
            },
            moveend(e) {
                let center = map.getCenter();
                if(props.callback){
                    props.callback(center);
                }
            }
        });

    return (
        <>
            <Marker icon={redIcon} position={props.position} ref={markerRef} ></Marker>
        </>
    )
};

export function MapLocation({position, callback}) {
    useEffect(() => {
        console.log('[MapLocation]');

    }, []);

    const handleMapChange = (value) => {
        if(callback){
            callback(value);
        }
    };

    return (<div className="map-container">
        {position? <MapContainer center={[position.lat, position.lng]} zoom={16} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker position={position} callback={handleMapChange} />
        </MapContainer>:null}
    </div>)
}