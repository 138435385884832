import React from 'react';
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {ListItem, ListItemButton} from "@mui/material";
import Alert from "@mui/material/Alert";
import {getPhoneNumber, getUserName} from "../../TextFormat";
import CardHeader from "@mui/material/CardHeader";

export default function Owner({value, relate=false}) {
    const { t } = useTranslation();
    const history = useHistory();

    const handleTel = (mobile) => {
        if(mobile){
            const link = document.createElement('a');
            link.id = 'phone';
            link.href = 'tel:'+mobile;
            link.click();
        }
    }

    const handleLinkClick= () => {
        history.push(`/users/${value.owner._id}`);
    }

    return(
        <div>
            <Card variant="outlined">
                <CardHeader
                    title={t('account.owner')}
                />
                {value.owner &&
                <>
                    <List sx={{paddingTop: '0px', paddingBottom: '0px'}}>
                        <ListItem key={value._id} disablePadding={true} secondaryAction={value.owner.mobile &&
                            <IconButton color={"primary"} edge="end" aria-label="tel"
                                        onClick={e => handleTel(value.owner.mobile)}>
                                <Avatar sx={value.owner.mobile?{background: "#1abc9c", width: 32, height: 32}:{width: 32, height: 32}}>
                                    <PhoneIcon fontSize={"small"}/>
                                </Avatar>
                            </IconButton>
                        }>
                            <ListItemButton onClick={handleLinkClick}>
                                <ListItemAvatar>
                                    <Avatar alt={value.owner.displayName} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${value.owner._id}`} />
                                </ListItemAvatar>
                                <ListItemText primary={getUserName(value.owner)}
                                              secondary={getPhoneNumber(value.owner.mobile)}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    {value.owner.state === 9 &&
                        <Box ml={2} mr={2} mb={2}>
                            <Alert severity="error" >{t('user.ban')}</Alert>
                        </Box>
                    }
                </>
                }
            </Card>
        </div>
    )
}