import React, {useState, useEffect} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {useTranslation} from "react-i18next";
import {httpClient} from "../../../core/HttpClient";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

ChartJS.register(ArcElement, Tooltip, Legend);
export default function SaleChart() {
    const { t } = useTranslation();
    let [isLoading, setIsLoading] = useState(true);
    let [data, setData] = useState({
        labels: [t('sale.closeSaleYes'), t('sale.closeSaleNo')],
        datasets: [
            {
                data: [0, 0],
                backgroundColor: [
                    'rgba(0, 220, 0, 0.8)',
                    'rgba(220, 0, 0, 0.8)',
                ],
            },
        ],
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "bottom"
            },
        },
    };


    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + `/admin/sale/summary`;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setData({
                        labels: [t('sale.closeSaleYes'), t('sale.closeSaleNo')],
                        datasets: [
                            {
                                data: [
                                    res.data['YES'] || 0,
                                    res.data['NO'] || 0
                                ],
                                backgroundColor: [
                                    'rgba(0, 220, 0, 0.8)',
                                    'rgba(220, 0, 0, 0.8)',
                                ],
                            },
                        ],
                    })
                }
                setIsLoading(false);
            });
    }, [t]);



    return (
        <div>
            {!isLoading && <Pie data={data} width={"200"} options={options} />}
            {isLoading &&
                <Box display="flex" justifyContent="center" mt={4} mb={4} pb={2}>
                    <CircularProgress size={20}/>
                </Box>
            }
        </div>
    )
}