import React from 'react';
import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PhoneIcon from '@mui/icons-material/Phone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {PatternFormat, NumericFormat} from 'react-number-format';
import {DateI18n} from "../../DateI18n";
import {getTextI18n} from "../../TextI18n";
import {httpClient} from "../../../core/HttpClient";
import {LastActive} from "../../LastActive";
import Select from "@mui/material/Select";
import VerifyByAdminDialog from "../VerifyByAdmin";
import Divider from "@mui/material/Divider";
import {ListItem, ListItemSecondaryAction} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {getPhoneNumber} from "../../TextFormat";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Verify from "../Verify";
import {MapLocation} from "../../MapLocation";
import Grid from "@mui/material/Grid";

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/s/${value.image.name}`;
    }
    return (<Avatar
        alt={name}
        variant={"square"}
        src={imageUrl}
    />)
};

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <PatternFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

const NumberFormatCustom = React.forwardRef((props, ref) =>
    <PatternFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="######"
    />);

const MemoizedMapContainer = React.memo(({position, callback})=>{
    return(<MapLocation position={position} callback={callback} />);
});

export default function Info({value, verification, whiteList = [], control=false, verifyProgress=false, onChange}) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(0);
    const [openAttachment, setOpenAttachment] = React.useState(false);
    const [openWhiteList, setOpenWhiteList] = React.useState(0);
    const [paymentAccountNameToDelete, setPaymentAccountNameToDelete] = React.useState('');
    const [openVerifyByAdmin, setOpenVerifyByAdmin] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [remainingDay, setRemainingDay] = React.useState(value.remainingDay);
    const [remainingCredit, setRemainingCredit] = React.useState(value.credit);
    const [paymentAccountName, setPaymentAccountName] = React.useState('');
    const [planOption, setPlanOption] = React.useState({key: 'menu', value: ''});
    const [plan, setPlan] = React.useState(value.plan);
    const [planOptions, setPlanOptions] = React.useState(value.planOptions);
    const [openPlanOptions, setOpenPlanOptions] = React.useState(0);
    const [accountProfile, setAccountProfile] = React.useState({});
    const [verified, setVerified] = React.useState(value.verified===0?false:true);
    const [status, setStatus] = React.useState(value.status);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [position, setPosition] = useState(null);
    const token = localStorage.getItem('admin-token');


    useEffect(() => {
        console.log('[Info]')
        setAccountProfile({name: value.name.i18n.th, address: value.address.i18n.th, phone: value.phone});
        if(value.latOrigin && value.lngOrigin){
            setPosition({lat: value.latOrigin, lng: value.lngOrigin});
        }
    },[value]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePlanChange = (e) => {
        setPlan(e.target.value);
    };

    const handleNumberChange = (e) => {
        setRemainingDay(e.target.value);
    };

    const handleCreditChange = (e) => {
        setRemainingCredit(e.target.value);
    };

    const handlePaymentAccountName = (e) => {
        setPaymentAccountName(e.target.value);
    };

    const handleOpen = (value) => {
        setAnchorEl(null);
        setOpen(value);
    };

    const handleClose = () => {
        setOpen(0);
    };

    const handleCloseCopied = () => {
        setCopied(false);
    };

    const handleVerifyChange = ({ target }) => {
        setVerified(target.checked);
    };

    const handleOpenAttachment = (value) => {
        setOpenAttachment(value);
    };

    const handleOpenWhiteList = (value) => {
        setOpenWhiteList(value);
    };
    const handleDeleteWhiteList = (value) => {
        setPaymentAccountNameToDelete(value);
        setOpenWhiteList(2);
    };

    const handleOpenPlanOptions = (value) => {
        setOpenPlanOptions(value);
    };
    const handleDeletePlanOptions = (value) => {
        setPlanOption({key: value.key, value: value.value});
        setOpenPlanOptions(2);
    };
    const handlePlanOptionKeyChange = (e) => {
        setPlanOption({key: e.target.value, value: ''});
    };
    const handlePlanOptionValueChange = (e) => {
        let newValue = {...planOption, value: e.target.value};
        setPlanOption(newValue);
    };

    const handleStatusChange = ({ target }) => {
        setStatus(target.value);
    };

    const handleUpdatePlan = () => {
        let myErrors = [];
        if(!remainingDay){
            myErrors.push('expireNumber');
        }
        if(!remainingCredit){
            if(remainingCredit !== 0){
                myErrors.push('creditNumber');
            }
        }
        if(myErrors.length === 0){
            setLoading(true);
            const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/plan';
            httpClient.put(url, {plan: plan, remainingDay: remainingDay, remainingCredit: remainingCredit})
                .then(res => {
                    setLoading(false);
                    setOpen(0);
                    onChange(res.data);
                });
        } else {
            setErrors(myErrors);
        }

    };

    const handleAddPaymentAccountName = (e) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/banks';
        httpClient.post(url, {value: paymentAccountName})
            .then(res => {
                setOpenWhiteList(0);
                onChange(res.data);
            });
    };

    const handleAddPlanOption = (e) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/plan/option';
        httpClient.post(url, {value: planOption})
            .then(res => {
                setOpenPlanOptions(0);
                setPlanOptions(res.data.planOptions);
            });
    };

    const handleDeletePlanOption = (e) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/plan/option';
        httpClient.put(url, {value: planOption})
            .then(res => {
                setOpenPlanOptions(0);
                setPlanOptions(res.data.planOptions);
            });
    };

    const handleDeletePaymentAccountName = (e) => {
        if(!paymentAccountNameToDelete){
            return;
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/banks';
        httpClient.put(url, {value: paymentAccountNameToDelete})
            .then(res => {
                setOpenWhiteList(0);
                onChange(res.data);
            });
    };

    const handleUpdateStatus = () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/status';
        let _data = {
            status: status,
            verified: 0
        };
        if(verified){
            _data.verified = 1;
        }
        httpClient.put(url, _data)
            .then(res => {
                setLoading(false);
                setOpen(0);
                onChange(res.data);
            });
    };

    const handleTel = (mobile) => {
        const link = document.createElement('a');
        link.id = 'phone';
        link.href = 'tel:'+mobile;
        link.click();
    }

    const handleProfileChange = ({ target }) => {
        setAccountProfile({ ...accountProfile, [target.name]: target.value });
    };

    const handleVerifyByAdminChange = (value) => {
        setOpenVerifyByAdmin(false);
        if(value){
            window.location.reload(true);
        }
    };

    const handleUpdateProfile = () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/profile';
        let _data = {...accountProfile};

        httpClient.put(url, _data)
            .then(res => {
                setLoading(false);
                setOpen(0);
                onChange(res.data);
            });
    };

    const handleUpload = ({ target }) => {
        setLoading(true);
        const data = new FormData();
        for(let x = 0; x < target.files.length; x++) {
            data.append('file', target.files[x]);
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/upload/secure/account';
        httpClient.post(url, data)
            .then(res => {
                if(res.data){
                    const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + value._id + '/document';
                    httpClient.put(url, res.data)
                        .then(res => {
                            setLoading(false);
                            setOpen(0);
                            onChange();
                        });
                }
            });
        target.value = ''; // fix same file select
    };

    const handleVerifyAction = (value) => {
        setLoading(true);
        window.location.reload(true);
    };

    const handleMapChange = ({ lat, lng }) => {
        setPosition({lat: lat, lng: lng});
        setAccountProfile({ ...accountProfile, latOrigin: lat, lngOrigin: lng });
    };

    return(
        <div>
            <Card variant="outlined">
                <CardHeader
                    title={t('account.title')}
                    action={ control &&
                        <>
                            <IconButton aria-label="action" onClick={handleMenuClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="control-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={e=>handleOpen(3)}>{t('account.changeProfile')}</MenuItem>
                                <MenuItem onClick={e=>handleOpen(1)}>{t('pricing.planAndRedemption')}</MenuItem>
                                <MenuItem onClick={e=>handleOpen(2)}>{t('account.statusAccount')}</MenuItem>
                            </Menu>
                        </>
                    }
                />
                {value.name &&
                <>
                    <CardHeader
                        avatar={
                            <MyAvatar value={value} />
                        }
                        title={<Typography>{value.name.i18n.th}</Typography>}
                        subheader={<LastActive value={value.lastActive} />}
                        action={<IconButton color={"primary"} edge="start" aria-label="tel" onClick={e=>handleTel(value.phone)} >
                            <Avatar sx={{background: "#1abc9c", width: 32, height: 32 }}>
                                <PhoneIcon fontSize={"small"} />
                            </Avatar>
                        </IconButton>}
                    />
                    {control &&
                    <Box p={2} pt={0}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('account.cif')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: 'gray'}}>
                                {value.cif}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('account.create')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: 'gray'}}>
                                <DateI18n value={value.created} format="DD MMM YYYY HH:mm" />
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('account.verify')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: value.verified===0?'red':'green'}}>
                                {value.verified===0?t('account.verifyFail'):t('account.verifyPass')}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('account.phone')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: 'gray'}}>
                                {getPhoneNumber(value.phone)}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('pricing.plan')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: 'gray'}}>
                                {value.plan}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('pricing.remainingDay')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: 'gray'}}>
                                <DateI18n value={value.expired} type="day" />
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('pricing.remainingCredit')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: 'gray'}}>
                                <NumericFormat value={value.credit} displayType={'text'} thousandSeparator={true} /> {t('common.credit')}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">
                                {t('account.expiration')}
                            </Typography>
                            <Typography variant="subtitle1" style={{color: 'gray'}}>
                                <DateI18n value={value.expired} format="DD MMM YYYY HH:mm" />
                            </Typography>
                        </Box>
                        {verification && verification._id &&
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="subtitle1">
                                    {t('account.attachment')}
                                </Typography>
                                <IconButton color="info" aria-label="attachments" sx={{right: '-10px'}} onClick={e=>handleOpenAttachment(true)}>
                                    <AttachFileIcon />
                                </IconButton>
                            </Box>
                        }

                        {value.status !== 'denied' && !value.verified &&
                            <Box mt={2}>
                                {!verifyProgress &&
                                    <>
                                        {[4,6].indexOf(verification.step) > -1 &&
                                            <Box mb={1}>
                                                <Verify value={verification} onAction={handleVerifyAction}/>
                                            </Box>
                                        }
                                        {!([4,6].indexOf(verification.step) > -1) &&
                                            <>
                                                <Alert severity="warning">{t('account.noVerify')}</Alert>
                                                <Box mt={1}>
                                                    <Button variant={"outlined"} color={"warning"}
                                                            fullWidth={true}
                                                            onClick={e=>setOpenVerifyByAdmin(true)}
                                                    >{t('account.verifyByAdmin')}</Button>
                                                </Box>
                                            </>
                                        }
                                    </>
                                }
                                {verifyProgress &&
                                    <Alert severity="info">{t('account.verifying')}</Alert>
                                }
                            </Box>
                        }
                        {value.status === 'denied' &&
                            <Box mt={2}>
                                <Alert severity="error">{t('account.denied')}</Alert>
                            </Box>
                        }
                    </Box>
                    }
                    {whiteList &&
                        <Box>
                            <Divider />
                            <Box p={2} pb={0} display={"flex"} justifyContent={"space-between"}>
                                <Typography variant={"h6"}>
                                    {t('whiteList.title')}
                                </Typography>
                                <IconButton color="info" aria-label="attachments" sx={{right: '-10px'}} onClick={e=>handleOpenWhiteList(1)}>
                                    <AddIcon />
                                </IconButton>
                            </Box>
                            <List>
                                {whiteList.map((value, index) => (
                                    <ListItem key={`white-list-${index}`} sx={{background: '#f0f0f0'}} divider={true}>
                                        <ListItemAvatar>
                                            <AccountBalanceIcon />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {value}
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton aria-label="delete" sx={{right: '-10px'}} onClick={e=>handleDeleteWhiteList(value)}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    }
                    <Box>
                        <Divider />
                        <Box p={2} pb={0} display={"flex"} justifyContent={"space-between"}>
                            <Typography variant={"h6"}>
                                {t('plan.options')}
                            </Typography>
                            <IconButton color="info" aria-label="attachments" sx={{right: '-10px'}} onClick={e=>handleOpenPlanOptions(1)}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        <List>
                            {planOptions.map((value, index) => (
                                <ListItem key={`plan-option-list-${index}`} sx={{background: '#f0f0f0'}} divider={true} >
                                    <ListItemText>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                {t(`plan.${value.key}`)}
                                            </Grid>
                                            <Grid item xs={6}>
                                                {value.value}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton aria-label="delete" sx={{right: '-10px'}} onClick={e=>handleDeletePlanOptions(value)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </>
                }
            </Card>
            <Dialog
                open={open>0}
                keepMounted
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                {open === 1 &&
                <>
                    <DialogContent>
                    <FormControl variant="outlined"
                                 fullWidth={true}>
                        <InputLabel id="plan-type-filled-label" >{t('pricing.plan')}</InputLabel>
                        <Select
                            native
                            labelId="plan-type-filled-label"
                            label={t('pricing.plan')}
                            name="plan"
                            value={plan}
                            onChange={handlePlanChange}>
                            <option value="FREE">FREE</option>
                            <option value="START">START</option>
                            <option value="BASIC">BASIC</option>
                            <option value="STANDARD">STANDARD</option>
                            <option value="PRO">PRO</option>
                        </Select>
                    </FormControl>
                    <Box mt={2} mb={2}>
                        <TextField
                            label={t('pricing.remainingDay')}
                            fullWidth={true}
                            name="expireNumber"
                            error={errors.indexOf('expireNumber') > -1}
                            type="tel"
                            value={remainingDay}
                            onChange={(e) => handleNumberChange(e)}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </Box>
                    <Box mt={2} mb={2}>
                        <TextField
                            label={t('pricing.remainingCredit')}
                            fullWidth={true}
                            name="creditNumber"
                            error={errors.indexOf('creditNumber') > -1}
                            type="tel"
                            value={remainingCredit}
                            onChange={(e) => handleCreditChange(e)}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                                color={"default"}
                                onClick={handleClose}
                                fullWidth={true}
                                size="large"
                                disableElevation>
                            {t('common.cancel')}
                        </Button>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                disabled={loading}
                                fullWidth={true}
                                onClick={(e) => handleUpdatePlan(e)}>
                            {t('common.confirm')}
                        </Button>
                    </DialogActions>
                </>
                }
                {open === 2 &&
                <>
                    <DialogContent>
                        <Box>
                            <FormLabel component="legend">{t('verification.title')}</FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={verified}
                                        onChange={handleVerifyChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={t('account.verifyPass')}
                            />
                        </Box>
                        <Box mt={1}>
                            <Box>
                                <FormLabel>{t('common.status')}</FormLabel>
                                <RadioGroup aria-label="status" name="status" value={status} onChange={handleStatusChange}>
                                    <FormControlLabel value="activated" control={<Radio color="primary" />} label={t('common.activate')} />
                                    <FormControlLabel value="denied" control={<Radio />} label={t('common.denied')} />
                                </RadioGroup>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                                color={"default"}
                                onClick={handleClose}
                                fullWidth={true}
                                size="large"
                                disableElevation>
                            {t('common.cancel')}
                        </Button>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                disabled={loading}
                                fullWidth={true}
                                onClick={(e) => handleUpdateStatus(e)}>
                            {t('common.confirm')}
                        </Button>
                    </DialogActions>
                </>
                }
                {open === 3 &&
                    <>
                        <DialogContent>
                            <Box>
                                <MemoizedMapContainer position={position} callback={handleMapChange} />
                            </Box>
                            <Box mt={2}>
                                <TextField id="account-name"
                                           label={t('account.name')}
                                           variant="outlined" fullWidth={true}
                                           name="name" value={ accountProfile.name }
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           onChange={ handleProfileChange } required />
                            </Box>
                            <Box mt={2}>
                                <TextField id="account-address"
                                           label={t('account.address')}
                                           variant="outlined" fullWidth={true}
                                           multiline rows={2}
                                           name="address" value={ accountProfile.address }
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           onChange={ handleProfileChange } required />
                            </Box>
                            <Box mt={2} mb={2}>
                                <TextField id="account-phone"
                                           label={t('account.phone')}
                                           variant="outlined"
                                           fullWidth={true}
                                           name="phone" type="tel"
                                           value={accountProfile.phone}
                                           onChange={ handleProfileChange }
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           InputProps={{
                                               inputComponent: PhoneFormatCustom,
                                           }}
                                           required />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained"
                                    color={"default"}
                                    onClick={handleClose}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.cancel')}
                            </Button>
                            <Button variant="contained"
                                    color="primary"
                                    size="large"
                                    disableElevation
                                    disabled={loading
                                        || accountProfile.name.trim().length === 0
                                        || accountProfile.address.trim().length === 0
                                        || accountProfile.phone.replace(/\D/g,'').length !== 10
                                        || !accountProfile.phone.startsWith('0')
                                    }
                                    fullWidth={true}
                                    onClick={(e) => handleUpdateProfile(e)}>
                                {t('common.confirm')}
                            </Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
            <Dialog
                open={openAttachment}
                fullScreen={true}
                keepMounted
                fullWidth={true}
                onClose={e=>handleOpenAttachment(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {verification.identificationCard &&
                        <Box mb={2}>
                            <Card>
                                <img
                                    style={{width: '100%'}}
                                    alt={verification.identificationCard.originalName}
                                    src={`${process.env.REACT_APP_API_BASE_URL}/upload/secure/${verification.identificationCard.key}?token=${token}`}
                                />
                            </Card>
                        </Box>
                    }
                    {verification.selfieWithCard &&
                        <Box mb={2}>
                            <Card>
                                <img
                                    style={{width: '100%'}}
                                    alt={verification.selfieWithCard.originalName}
                                    src={`${process.env.REACT_APP_API_BASE_URL}/upload/secure/${verification.selfieWithCard.key}?token=${token}`}
                                />
                            </Card>
                        </Box>
                    }
                    {verification.shopOverviewPhoto &&
                        <Box>
                            <Card>
                                <img
                                    style={{width: '100%'}}
                                    alt={verification.shopOverviewPhoto.originalName}
                                    src={`${process.env.REACT_APP_API_BASE_URL}/upload/secure/${verification.shopOverviewPhoto.key}?token=${token}`}
                                />
                            </Card>
                        </Box>
                    }
                    {verification.moreDocuments &&
                        <Box mb={2}>
                            {verification.moreDocuments.map((value, index) => (
                                <Box key={`more-documents-${index}`} mb={2}>
                                    <Card>
                                        <img
                                            style={{width: '100%'}}
                                            alt={value.originalName}
                                            src={`${process.env.REACT_APP_API_BASE_URL}/upload/secure/${value.key}?token=${token}`}
                                        />
                                    </Card>
                                </Box>
                            ))}
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="center" pl={2} pr={2}>
                        <input
                            accept="image/*"
                            style={{display: 'none'}}
                            id="contained-button-file"
                            onChange={handleUpload}
                            type="file"
                        />
                        <label htmlFor="contained-button-file" style={{width: '100%'}}>
                            <Button
                                variant="outlined"
                                disableElevation
                                component="span"
                                fullWidth={true}
                                size="large"
                                startIcon={<CloudUploadIcon/>}>
                                {t('common.upload')}
                            </Button>
                        </label>
                    </Box>
                    <Button variant="contained"
                            color={"default"}
                            onClick={e=>handleOpenAttachment(false)}
                            fullWidth={true}
                            size="large"
                            disableElevation>
                        {t('common.close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openWhiteList > 0}
                keepMounted
                fullWidth={true}
                onClose={e=>handleOpenWhiteList(0)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Box p={2} mt={2}>
                    {openWhiteList === 1 &&
                    <TextField id="whiteList-title"
                               label={t('whiteList.title')}
                               variant="outlined" fullWidth={true}
                               name="paymentAccountName" value={ paymentAccountName }
                               onChange={ handlePaymentAccountName } required />
                    }
                    {openWhiteList === 2 &&
                        <Typography variant={"h6"}>
                            {t('whiteList.confirmDelete')}
                        </Typography>
                    }
                </Box>
                <DialogActions>
                    {openWhiteList === 1 &&
                        <Button variant="contained"
                                color={"primary"}
                                onClick={handleAddPaymentAccountName}
                                fullWidth={true}
                                size="large"
                                disableElevation>
                            {t('common.save')}
                        </Button>
                    }
                    {openWhiteList === 2 &&
                        <>
                            <Button variant="contained"
                                    color={"default"}
                                    onClick={e=>handleOpenWhiteList(0)}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.cancel')}
                            </Button>
                            <Button variant="contained"
                                    color={"secondary"}
                                    onClick={handleDeletePaymentAccountName}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.delete')}
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                open={openPlanOptions > 0}
                keepMounted
                fullWidth={true}
                onClose={e=>handleOpenPlanOptions(0)}
                aria-labelledby="alert-dialog-plan-options"
                aria-describedby="alert-dialog-plan-options"
            >
                <Box p={2} mt={2}>
                    {openPlanOptions === 1 &&
                        <Box>
                            <FormControl variant="outlined"
                                         fullWidth={true}>
                                <InputLabel id="plan.planOption-label">{t('plan.optionItem')}</InputLabel>
                                <Select
                                    native
                                    labelId="plan.planOption-label"
                                    label={t('plan.optionItem')}
                                    name="planOptionKey"
                                    value={planOption.key}
                                    onChange={handlePlanOptionKeyChange}>
                                    <option value="menu">{t('plan.menu')}</option>
                                    <option value="table">{t('plan.table')}</option>
                                    <option value="kitchen">{t('plan.kitchen')}</option>
                                    <option value="user">{t('plan.user')}</option>
                                    <option value="area">{t('plan.area')}</option>
                                </Select>
                            </FormControl>
                            <Box mt={2} mb={2}>
                                <TextField
                                    fullWidth={true}
                                    name="planOptionValue"
                                    error={errors.indexOf('planOptionValue') > -1}
                                    type="tel"
                                    value={planOption.value}
                                    onChange={(e) => handlePlanOptionValueChange(e)}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}
                                />
                            </Box>
                        </Box>
                    }
                    {openPlanOptions === 2 &&
                        <Typography variant={"h6"}>
                            {t('plan.confirmDeleteOption')}
                        </Typography>
                    }
                </Box>
                <DialogActions>
                    {openPlanOptions === 1 &&
                        <Button variant="contained"
                                color={"primary"}
                                onClick={handleAddPlanOption}
                                fullWidth={true}
                                size="large"
                                disabled={planOption.value === ''}
                                disableElevation>
                            {t('common.save')}
                        </Button>
                    }
                    {openPlanOptions === 2 &&
                        <>
                            <Button variant="contained"
                                    color={"default"}
                                    onClick={e=>handleOpenPlanOptions(0)}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.cancel')}
                            </Button>
                            <Button variant="contained"
                                    color={"secondary"}
                                    onClick={handleDeletePlanOption}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.delete')}
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
            <VerifyByAdminDialog account={value} verification={verification} open={openVerifyByAdmin}
                                 onClose={handleVerifyByAdminChange} />
            <Snackbar open={copied}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseCopied}>
                <Alert onClose={handleCloseCopied} severity="info">
                    {t('common.message.copied')}
                </Alert>
            </Snackbar>
        </div>
    )
}