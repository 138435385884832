import React, { useReducer, useRef, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {Badge, ListItemButton, Tab, Tabs, useTheme} from "@mui/material";
import TaskList from "./TaskList";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {makeStyles} from "@mui/styles";
import {alpha} from "@mui/material/styles";
import {httpClient} from "../../core/HttpClient";
import _ from "lodash";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {DateI18n} from "../DateI18n";
import {useHistory} from "react-router-dom";

let controller = null;
const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch) {
    console.log('fetchHits');
    dispatch({ type: "FETCH_START" });
    try {
        const signal = controller.signal;
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/tasks';
        const result = await httpClient(
            `${url}?q=${query.search}&c=${query.cursor}`,
            { signal }
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data, cursor: query.cursor })
    } catch (err) {
        console.log('err', err);
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

let MyItemTitle = ({task, t}) => {
    return (<ListItemText
        primary={<Box>
            <Typography variant="body1">
                {t(`task.actions.${task.action}`)}
            </Typography>
            <Typography variant="body1">
                {t('task.refNo')}: {task.refNo}
            </Typography>
        </Box>
        }
        secondaryTypographyProps={{component: 'div'}}
        secondary={<Box display={"flex"} justifyContent={"space-between"}>
            <DateI18n value={task.modified} format="DD MMM YYYY HH:mm" />
            <Typography variant="body1">
                {t(`task.statues.${task.status}`)}
            </Typography>
        </Box>
        } />);
};

export default function Tasks() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [query, setQuery] = useState({cursor: '', search: ''});
    const [{ hits, isLoading }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null
    });

    const debouncedFetchHits = useRef(
        _.debounce(
            (query) => fetchHits(query, dispatch),
            800
        )
    ).current;

    useEffect(() => {
        console.log('Tasks');
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleQueryChange = (value) =>{
        setQuery({cursor: '', search: value});
        if(controller){
            controller.abort();
            controller = new AbortController();
        } else {
            controller = new AbortController();
        }
        const cancelToken = httpClient.CancelToken.source();
        let df = debouncedFetchHits({cursor: '', search: value}, cancelToken.token);
        if(df){
            df.then(r=>{});
        }
    };

    const handleClick= (task) =>{
        if(task.action === 'VERIFICATION'){
            history.push(`/accounts/${task.account._id}`);
        } else {
            history.push(`/tasks/${task._id}`);
        }
    }

    return (
        <div>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <Typography variant="h6">
                            {t('task.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={t('invoice.searchExample')}
                                fullWidth={true}
                                className={classes.inputInput}
                                style={{
                                    border: '1px solid gainsboro',
                                    borderRadius: '5px',
                                    paddingLeft: `calc(1em + 18px)`,
                                }}
                                value={query.search}
                                onChange={event => handleQueryChange(event.target.value)}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {query.search !== '' &&
            <Box>
                {isLoading &&
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={2}>
                        <Typography variant={"caption"}>{t('task.searching')}...</Typography>
                    </Box>
                }
                {!isLoading &&
                    <List>
                        {hits.map((value, index) => (
                            <ListItemButton key={`item-${value._id}-${index}`}
                                            onClick={()=>handleClick(value)}
                            >
                                <ListItemAvatar>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            value.action === 'INDICTMENT'?
                                                <Avatar sx={{ width: 20, height: 20, border: '2px solid white' }}
                                                        alt="Profile"
                                                        src={(value.customer)?`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${value.customer}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                                                : <Avatar sx={{ width: 20, height: 20, border: '2px solid white' }}
                                                          alt="Profile"
                                                          src={(value.user)?`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${value.user._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                                        }
                                    >
                                        <Avatar sx={{ width: 36, height: 36 }}
                                                alt="Account"
                                                variant={"square"}
                                                src={(value.account.image && value.account.image.name)?`${process.env.REACT_APP_CDN_BASE_URL}/${value.account.image.permission}/merchant/o/${value.account.image.name}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                                    </Badge>

                                </ListItemAvatar>
                                <ListItem key={value._id} divider={true}>
                                    <MyItemTitle task={value} t={t} />
                                </ListItem>
                            </ListItemButton>
                        ))}
                    </List>
                }

            </Box>
            }
            {query.search === '' &&
            <Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={t('task.news')} {...a11yProps(0)} />
                    <Tab label={t('task.inProgress')} {...a11yProps(1)} />
                    <Tab label={t('task.done')} {...a11yProps(2)} />
                </Tabs>
                <div>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <TaskList status="pending"></TaskList>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <TaskList status="progress"></TaskList>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <TaskList status="completed"></TaskList>
                    </TabPanel>
                </div>
            </Box>
            }
        </div>
    )
}