import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {httpClient} from '../../core/HttpClient'

import './style.css';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = localStorage.getItem('admin-token');

const MyListItem = ({sortIndex, onRemove, value}) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar variant={"square"} src={`${process.env.REACT_APP_API_BASE_URL}/upload/secure/${value.key}?token=${token}`} />
        </ListItemAvatar>
        <ListItemText>
            <Typography variant="body1" noWrap={true}>
                {value.originName}
            </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete"
                        onClick={() => onRemove(sortIndex)}>
                <DeleteIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
);

export function DocumentsUpload({account, value, onChange}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState({upload: false});
    const [images, setImages] = useState([]);
    const [confirm, setConfirm] = React.useState({open: false});
    const accountId = account._id;

    useEffect(() => {
        console.log('[DocumentsUpload]');
        setImages(value);
    }, [value]);

    const handleUpload = ({ target }) => {
        console.log('handleUpload');
        setLoading({upload: true});
        const data = new FormData();
        for(let x = 0; x < target.files.length; x++) {
            data.append('file', target.files[x]);
        }

        // const url = process.env.REACT_APP_API_BASE_URL + '/upload/secure/account';
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/'+accountId+'/secure/upload';
        httpClient.post(url, data)
            .then(res => {
                if(res.data){
                    const newList = images.concat(res.data);
                    setImages(newList);
                    let myTarget = {
                        target: {
                            name: 'images',
                            value: newList
                        }
                    };
                    onChange(myTarget);
                }
                setLoading({upload: false});
            });
        target.value = ''; // fix same file select
    };

    const onRemove = (index) => {
        setConfirm({open: true, index: index});
    };

    const handleRemove = () => {
        let index = confirm.index;
        images.splice(index, 1);
        let newList = [].concat(images);
        setImages(newList);
        setConfirm({open: false});
    };

    const handleClose = () => {
        setConfirm({open: false});
    };

    return(
        <div>
            {images.length > 0 &&
            <List>
                {images.map((value, index) => (
                    <MyListItem key={`item-${index}`} index={index} value={value}
                                  onRemove={ onRemove }
                                  sortIndex={index} />
                ))}
            </List>
            }
            {loading.upload &&
            <Box display="flex" justifyContent="center" mt={4} mb={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading.upload &&
            <Box display="flex" justifyContent="center" pl={2} pr={2}>
                <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="documents-button-file"
                    onChange={handleUpload}
                    type="file"
                />
                <label htmlFor="documents-button-file" style={{width: '100%'}}>
                    <Button
                        variant="outlined"
                        disableElevation
                        component="span"
                        fullWidth={true}
                        startIcon={<CloudUploadIcon/>}>
                        {t('common.upload')}
                    </Button>
                </label>
            </Box>
            }
            <Dialog
                open={confirm.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('common.message.confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('common.message.confirmDelete')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            onClick={handleClose}
                            fullWidth={matches}
                            size="large"
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained"
                            color="secondary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            onClick={ handleRemove }
                            startIcon={<DeleteIcon />}>
                        {t('common.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};