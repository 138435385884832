import React from 'react';
import { createBrowserHistory } from "history";
import './index.css';
import './i18n/config';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';

// Create your Own theme:
const theme = createTheme({
    palette: {
        primary: {
            main: '#1abc9c',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#f5404c',
        },
        orange: {
            // main: '#ff9800',
            main: '#ffa600',
            contrastText: '#ffffff',
        },
        success: {
            main: '#44cc00',
            contrastText: '#ffffff',
        },
        default: {
            light: '#e0e0e0',
            main: '#dddddd',
            contrastText: '#000000',
        }
    },
    overrides: {
        MuiButton: {
            root: {
                "&:not($sizeLarge):not($sizeSmall) $label": {
                    fontSize: "1rem",
                }
            },
            sizeLarge: {
                "& $label": {
                    fontSize: "1.2rem",
                }
            }
        }
    }
});


const history = createBrowserHistory();
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <ThemeProvider theme={theme}>
        <Routes history={history} />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
