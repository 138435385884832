import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const liff = window.liff;
const lineAuthentication = async(cb) => {
    const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    await liff.init({ liffId: liffId }).catch(err=>{throw err});
    if (liff.isLoggedIn()) {
        const accessToken = await liff.getAccessToken();
        return cb(accessToken);
    }else{
        liff.login(); //external browser can not use liff.login(); for verify token
    }
};

export default function SignIn() {
    const history = useHistory();
    const { mode, key } = useParams();

    useEffect(() => {
        lineAuthentication(accessToken=>{
            if(accessToken){
                console.log('accessToken', accessToken);

                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({accessToken: accessToken, redirectUri: process.env.REACT_APP_BASE_URL+'/login'})
                };

                const url = process.env.REACT_APP_API_BASE_URL + '/admin/auth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if (data.token) {
                            localStorage.setItem('admin-token', data.token);
                            localStorage.setItem('displayName', data.displayName);
                            localStorage.setItem('pictureUrl', `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${data.userId}`);
                            history.push('/home');
                        } else {
                            localStorage.removeItem('admin-token');
                            history.push('/login');
                        }

                    });
            }
        });
    }, [history, mode, key]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20} />
            </Box>
        </div>
    )
}