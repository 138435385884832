import React from 'react';
import {Route, Router} from 'react-router';
import {Redirect, Switch} from 'react-router-dom';
import Home from './components/Home';
import Accounts from './components/Accounts';
import AccountInfo from './components/AccountInfo';
import Invoices from './components/Invoices';
import Login from './components/Login';
import SignIn from './components/SignIn';
import ConsoleLayoutRoute from "./layouts/Console";
import Tasks from "./components/Tasks";
import TaskInfo from "./components/Tasks/TaskInfo";
import {MoreMenu} from "./components/MoreMenu";
import {Sales} from "./components/Sales";
import {SaleInfo} from "./components/Sales/info";
import UserInfo from "./components/UserInfo";
import Users from "./components/Users";

const Routes = (props) => (
    <Router {...props}>
        <Switch>
            <ConsoleLayoutRoute path="/home" component={Home} />
            <ConsoleLayoutRoute path="/accounts/:id" component={AccountInfo} />
            <ConsoleLayoutRoute path="/accounts" component={Accounts} />
            <ConsoleLayoutRoute path="/users/:id" component={UserInfo} />
            <ConsoleLayoutRoute path="/users" component={Users} />
            <ConsoleLayoutRoute path="/invoices" component={Invoices} />
            <ConsoleLayoutRoute path="/mores" component={MoreMenu} />
            <ConsoleLayoutRoute path="/tasks/:id" component={TaskInfo} />
            <ConsoleLayoutRoute path="/tasks" component={Tasks} />
            <ConsoleLayoutRoute path="/sales/:id" component={SaleInfo} />
            <ConsoleLayoutRoute path="/sales" component={Sales} />
            <Route path="/signin" component={SignIn} />
            <Route path="/login" component={Login} />
            <Redirect to="/login" />
        </Switch>
    </Router>
);

export default Routes;