import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {useParams} from "react-router-dom";
import {httpClient} from "../../../core/HttpClient";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {DateI18n} from "../../DateI18n";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import TaskBuyer from "../TaskBuyer";
import TaskShop from "../TaskShop";
import TaskReporter from "../TaskReporter";
import {NumericFormat} from "react-number-format";
import {ImageList, ImageListItem} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";


export default function TaskInfo() {
    const { t } = useTranslation();
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [openAttachment, setOpenAttachment] = React.useState(false);
    const [task, setTask] = useState({status: 'pending', trackingNumber: '', deliveryDate: '', deliveryExpress: 'THAILAND_POST',
        resultIndictment: '', resultRemark: ''});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        console.log('TaskList');
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/tasks/'+id;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    let _data = {...res.data,
                        trackingNumber: res.data.trackingNumber || '',
                        deliveryDate: res.data.deliveryDate || new Date(),
                        deliveryExpress: res.data.deliveryExpress || 'THAILAND_POST',
                        resultIndictment: res.data.resultIndictment || 'guilty',
                        resultRemark: res.data.resultRemark || ''
                    };
                    setTask(_data);
                }
                setLoading(false);
            });
    }, [id]);

    const _refreshData = ()=>{
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/tasks/'+id;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setTask(res.data);
                }
                setLoading(false);
            });
    }

    const handleChange = ({ target }) => {
        setTask({ ...task, [target.name]: target.value });
    };

    const handleCloseMessage = () => {
        setUpdated(false);
    };

    const handleSelectChange = ({ target }) => {
        setTask({...task, [target.name]: target.value});
    };

    const handleClick= () =>{
        let data = {
            status: task.status,
            trackingNumber: task.trackingNumber,
            deliveryDate: task.deliveryDate,
            deliveryExpress: task.deliveryExpress,
            resultIndictment: task.resultIndictment,
            resultRemark: task.resultRemark
        };
        if(data.status === 'completed'){
            if(task.action === 'STORE_ORDERS') {
                if(!data.trackingNumber || data.trackingNumber.length === 0){
                    setErrors(['trackingNumber'])
                    return;
                }
            }
        }

        setErrors([]);
        setUpdating(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/tasks/'+id;
        httpClient.put(url, data)
            .then(res => {
                setUpdating(false);
                setUpdated(true);
                _refreshData();
            });
    }

    const handleOpenAttachment = (value) => {
        setOpenAttachment(value);
    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" pt={1} pl={1}>
                <Typography variant="h6">
                    {t('task.detail')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box p={1}>
                <Card variant={"outlined"}>
                    <CardContent>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography gutterBottom variant="h6">
                                {t('task.info')}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body1">
                                {t('task.title')}
                            </Typography>
                            <Typography variant="body1">
                                {task.issue}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body1">
                                {t('task.refNo')}
                            </Typography>
                            <Typography variant="body1">
                                {task.refNo}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body1">
                                {t('task.created')}
                            </Typography>
                            <Typography variant="body1">
                                <DateI18n value={task.created} format="DD MMM YYYY HH:mm" />
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body1">
                                {t('task.updated')}
                            </Typography>
                            <Typography variant="body1">
                                <DateI18n value={task.modified} format="DD MMM YYYY HH:mm" />
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body1">
                                {t('task.status')}
                            </Typography>
                            <Typography variant="body1">
                                {t(`task.statues.${task.status}`)}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box p={1}>
                <Card variant={"outlined"}>
                    <CardContent>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography gutterBottom variant="h6">
                                {t('task.detail')}
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <Typography gutterBottom variant="body1">
                                <strong>{task.issue}</strong>
                            </Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography gutterBottom variant="body1">
                                {task.detail}
                            </Typography>
                        </Box>
                        {task.action === 'INDICTMENT' && task.indictment && task.indictment.images &&
                        <Box>
                            <ImageList cols={5}>
                                {task.indictment.images.map((item, index) => (
                                    <ImageListItem key={`image-${index}`} onClick={e=>handleOpenAttachment(true)}>
                                        <img
                                            style={{width: '100%', border: '1px solid lightgray', padding: '3px'}}
                                            alt={item.originalName}
                                            src={`${process.env.REACT_APP_CDN_BASE_URL}/${item.key}`}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                        }

                        {task.action === 'STORE_ORDERS' &&
                        <>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body1">
                                    {t('task.productCode')}
                                </Typography>
                                <Typography variant="body1">
                                    <b>{task.productCode}</b>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body1">
                                    {t('task.qty')}
                                </Typography>
                                <Typography variant="body1">
                                    <b>{task.qty} {t('task.unit')}</b>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body1">
                                    {t('task.paid')}
                                </Typography>
                                <Typography variant="body1">
                                    <b><NumericFormat value={task.amount || 0} displayType={'text'}
                                                      thousandSeparator={true} prefix={"฿"}/></b>
                                </Typography>
                            </Box>
                        </>
                        }
                        {task.remark &&
                            <Box>
                                <Typography gutterBottom variant="caption" color={"red"}>
                                    * {task.remark}
                                </Typography>
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Box>
            {['INDICTMENT'].indexOf(task.action) > -1 &&
                <Box p={1}>
                    <TaskReporter value={task} />
                </Box>
            }
            {['STORE_ORDERS'].indexOf(task.action) > -1 &&
                <Box p={1}>
                    <TaskBuyer value={task} />
                </Box>
            }
            {task.account &&
            <Box mb={1} p={1}>
                <TaskShop value={task.account}  />
            </Box>
            }
            {!loading &&
                <Box p={1}>
                    <Card variant={"outlined"}>
                        <CardContent>
                            <Box>
                                <FormControl variant="outlined"
                                             fullWidth={true}>
                                    <InputLabel id="status-label">{t('task.status')}</InputLabel>
                                    <Select
                                        native
                                        labelId="status-label"
                                        label={t('task.status')}
                                        name="status"
                                        value={task.status}
                                        onChange={handleSelectChange}>
                                        <option value="pending">{t('task.statues.pending')}</option>
                                        <option value="progress">{t('task.statues.progress')}</option>
                                        <option value="completed">{t('task.statues.completed')}</option>
                                    </Select>
                                </FormControl>
                            </Box>
                            {task.status === 'completed' && ['INDICTMENT'].indexOf(task.action) === -1 &&
                                <>
                                <Box mt={2}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}>
                                        <InputLabel id="status-label">{t('task.deliveryExpress')}</InputLabel>
                                        <Select
                                            native
                                            labelId="status-label"
                                            label={t('task.deliveryExpress')}
                                            name="deliveryExpress"
                                            value={task.deliveryExpress}
                                            onChange={handleSelectChange}>
                                            <option value="THAILAND_POST">{t('task.express.thailandpost')}</option>
                                            <option value="KERRY">{t('task.express.kerry')}</option>
                                            <option value="FLASH">{t('task.express.flash')}</option>
                                            <option value="J_AND_T">{t('task.express.jandt')}</option>
                                            <option value="NINJA_VAN">{t('task.express.ninjavan')}</option>
                                            <option value="BEST">{t('task.express.best')}</option>
                                            <option value="DHL">{t('task.express.dhl')}</option>
                                            <option value="SCG">{t('task.express.scg')}</option>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <TextField
                                            label={t('delivery.trackingNumber')}
                                            variant="outlined"
                                            name={'trackingNumber'}
                                            value={task.trackingNumber}
                                            onChange={handleChange}
                                            error={errors.indexOf('trackingNumber') > -1}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                                        <MobileDatePicker
                                            label={t('delivery.deliveryDate')}
                                            showToolbar={false}
                                            value={task.deliveryDate}
                                            onChange={(newValue) => {
                                                setTask({...task, deliveryDate: newValue});
                                            }}
                                            renderInput={(params) => <TextField fullWidth={true} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                </>
                            }
                            {task.status === 'completed' && ['INDICTMENT'].indexOf(task.action) > -1 &&
                                <>
                                    <Box mt={2}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}>
                                            <InputLabel id="indictment-label">{t('task.indictment.result')}</InputLabel>
                                            <Select
                                                native
                                                labelId="indictment-label"
                                                label={t('task.indictment.result')}
                                                name="resultIndictment"
                                                value={task.resultIndictment}
                                                onChange={handleSelectChange}>
                                                <option value="guilty">{t('task.indictment.results.guilty')}</option>
                                                <option value="notGuilty">{t('task.indictment.results.notGuilty')}</option>
                                                <option value="insufficient">{t('task.indictment.results.insufficient')}</option>
                                                <option value="other">{t('task.indictment.results.other')}</option>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box mt={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <TextField
                                                label={t('task.indictment.remark')}
                                                variant="outlined"
                                                name={'resultRemark'}
                                                value={task.resultRemark}
                                                onChange={handleChange}
                                                error={errors.indexOf('remark') > -1}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                </>
                            }
                            <Box mt={2}>
                                <Button variant="contained"
                                        color="primary"
                                        size="large"
                                        disableElevation
                                        disabled={updating}
                                        fullWidth={true}
                                        onClick={(e) => handleClick()}>
                                    {t('common.save')}
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            }
            <Dialog
                open={openAttachment}
                fullScreen={true}
                keepMounted
                fullWidth={true}
                onClose={e=>handleOpenAttachment(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {task.indictment && task.indictment.images &&
                        <Box mb={2}>
                            {task.indictment.images.map((value, index) => (
                                <Box key={`image-view-${index}`} mb={2}>
                                    <Card>
                                        <img
                                            style={{width: '100%', verticalAlign: 'bottom'}}
                                            alt={value.originalName}
                                            src={`${process.env.REACT_APP_CDN_BASE_URL}/${value.key}`}
                                        />
                                    </Card>
                                </Box>
                            ))}
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            color={"default"}
                            onClick={e=>handleOpenAttachment(false)}
                            fullWidth={true}
                            size="large"
                            disableElevation>
                        {t('common.close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={updated}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseMessage}>
                <Alert onClose={handleCloseMessage} severity="success">
                    {t('common.message.saveSuccess')}
                </Alert>
            </Snackbar>
        </div>
    )
}