import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../core/CropImage'
import {httpClient} from "../../core/HttpClient";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inputBrowse: {
        display: 'none',
    },
    media: {
        height: 0,
        paddingTop: '52.35%',
        cursor: 'pointer'
    },
}));

export function ImageUploads({name, values, aspect, placeholder='empty.png', onChange}) {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const classes = useStyles();
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropDialog, setCropDialog] = useState(false);
    const [cropImage, setCropImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        console.log('[ImageUploads]');
        if(values && values.length > 0){
            setImages(values);
        } else {
            setImages([]);
        }
    },[values]);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                cropImage,
                croppedAreaPixels,
                0
            )
            setCropDialog(false);
            setLoading(true);
            const data = new FormData();
            data.append('file', croppedImage);
            let url = process.env.REACT_APP_API_BASE_URL + '/upload/image';
            httpClient.post(url, data).then(res => {
                if(res.data){
                    setImages(images.concat(res.data));
                    let myTarget = {
                        target: {
                            name: name,
                            value: { ...res.data }
                        }
                    };
                    onChange(myTarget);
                }
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        }
    }, [cropImage, images, croppedAreaPixels, name, onChange]);

    const handleUpload = ({ target }) => {
        if(target.files[0]){
            const reader = new FileReader();
            reader.onloadend = () => {
                setCropImage(reader.result);
            };
            reader.readAsDataURL(target.files[0]);
            target.value = ''; // fix same file select
            setCropDialog(true);
        }
    };

    const handleClose = () =>{
      setCropDialog(false);
    };

    const handleUploadClick = () => {
        inputRef.current.click();
    };

    return(
        <>
            <label htmlFor={`contained-button-file-${name}}`} style={{textAlign: 'center'}}></label>
            <input
                ref={inputRef}
                accept="image/*"
                className={classes.inputBrowse}
                id={`contained-button-file-${name}}`}
                name={`contained-button-file-${name}}`}
                onChange={handleUpload}
                type="file"
            />
            <Button variant="outlined"
                    fullWidth={true}
                    color="info"
                    disabled={loading}
                    startIcon={<PhotoCameraIcon />}
                    onClick={handleUploadClick}>
                {t('common.upload')}
            </Button>
            {cropDialog &&
            <Dialog
                onClose={ handleClose }
                fullScreen={true}
                open={ cropDialog }>
                <DialogContent>
                    <Cropper
                        image={cropImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button color='primary'
                                    variant="contained"
                                    onClick={showCroppedImage}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.ok')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained"
                                    onClick={handleClose}
                                    fullWidth={true}
                                    size="large"
                                    color={"grey"}
                                    disableElevation>
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            }
        </>
    )
}