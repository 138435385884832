import React from 'react';
import { useTranslation } from "react-i18next";

export function getProvinceI18n(data, i18n) {
    const currentLang = i18n.language.split('-')[0] || 'en';
    if(data){
        let key = 'name_'+currentLang;
        return data[key];
    } else {
        return '';
    }
}

export function getTextI18n(data) {
    if(data && data.i18n){
        return data.i18n['th'] || data.i18n['en'];
    } else {
        return '';
    }
}

export function TextI18n(props) {
    const { i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0] || 'en';
    return(
        <span>
            {props.value &&
                props.value.i18n[currentLang]
            }
        </span>
    )
}