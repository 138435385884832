import React from 'react';
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {ListItem, ListItemSecondaryAction} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import {getPhoneNumber} from "../../TextFormat";

export default function TaskReporter({value}) {
    const { t } = useTranslation();

    const handleTel = (mobile) => {
        const link = document.createElement('a');
        link.id = 'phone';
        link.href = 'tel:'+mobile;
        link.click();
    }

    return(
        <div>
            <Card variant={"outlined"}>
                <CardHeader
                    title={t('reporter.title')}
                />
                {value && value.customer &&
                    <Box>
                        <List sx={{paddingTop: '0px'}}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar alt="Profile"
                                            src={(value.customer) ? `${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${value.customer._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}/>
                                </ListItemAvatar>
                                <ListItemText primary={value.customer.displayName}
                                              secondary={getPhoneNumber(value.customer.mobile)}
                                />
                                {value.customer.mobile &&
                                    <ListItemSecondaryAction>
                                        <IconButton color={"primary"} edge="end" aria-label="tel"
                                                    onClick={e => handleTel(value.customer.mobile)}>
                                            <Avatar sx={{background: "#1abc9c", width: 32, height: 32}}>
                                                <PhoneIcon fontSize={"small"}/>
                                            </Avatar>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                        </List>
                    </Box>
                }
            </Card>
        </div>
    )
}