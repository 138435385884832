import React, { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Paper} from "@mui/material";

const FooterMenu = forwardRef((props, ref) => {
    const pathname = window.location.pathname;
    const { t } = useTranslation();

    return (
        <footer>
            <Paper sx={{zIndex: 999, position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    value={pathname}
                    style={{height: '75px'}}
                    showLabels>
                    <BottomNavigationAction label={t('dashboard.title')}
                                            icon={<DashboardIcon/>}
                                            component={Link}
                                            to='/home'
                                            value='/home' />
                    <BottomNavigationAction label={t('task.title')}
                                            icon={<AssignmentIcon/>}
                                            component={Link}
                                            to="/tasks"
                                            value="/tasks"/>
                    <BottomNavigationAction label={t('account.title')}
                                            icon={<StorefrontIcon/>}
                                            component={Link}
                                            to="/accounts"
                                            value="/accounts"/>
                    <BottomNavigationAction label={t('user.title')}
                                            icon={<AccountCircleIcon/>}
                                            component={Link}
                                            to="/users"
                                            value="/users"/>
                    <BottomNavigationAction label={t('more.title')}
                                            icon={<MoreHorizIcon/>}
                                            component={Link}
                                            to="/mores"
                                            value="/mores"/>
                </BottomNavigation>
            </Paper>
        </footer>
    )
});

export default FooterMenu;