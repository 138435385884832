import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Owner from "./Owner";
import Info from "./Info";
import {QRCode} from "../QRCode";
import {httpClient} from "../../core/HttpClient";
import dayjs from 'dayjs';
import {PartnerInfo} from "../Partners/Info";
import AccountInvoice from "./Invoices";

export default function AccountInfo() {
    const { t } = useTranslation();
    const {id} = useParams();
    const [account, setAccount] = useState({});
    const [verification, setVerification] = useState({});
    const [partner, setPartner] = useState({products: []});
    const [whiteList, setWhiteList] = useState([]);
    const [qrValue, setQrValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [generating, setGenerating] = useState(true);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + id;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    let _account = res.data.account;
                    if(_account){
                        let remainingDay = 0;
                        let remainingHours = dayjs(_account.expired).diff(new Date(), 'hours');
                        if(remainingHours > 0){
                            remainingDay = Math.ceil(remainingHours / 24);
                        }
                        remainingDay = remainingDay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        let _data = {..._account, remainingDay: remainingDay};
                        setAccount(_data);
                        setQrValue('1/'+_data.cid); // dynamic qr code
                    }

                    if(res.data.verification){
                        setVerification(res.data.verification);
                    } else {
                        setVerification({});
                    }

                    if(res.data.partner){
                        setPartner(res.data.partner);
                    }

                    if(res.data.whiteList){
                        setWhiteList(res.data.whiteList);
                    }
                }
                setGenerating(false);
                setLoading(false);
            });
    },[id]);

    const handlePlanChange = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/account/' + id;
        httpClient.get(url)
            .then(res => {
                let _account = res.data.account;
                if(_account){
                    let remainingDay = 0;
                    let remainingHours = dayjs(_account.expired).diff(new Date(), 'hours');
                    if(remainingHours > 0){
                        remainingDay = Math.ceil(remainingHours / 24);
                    }
                    remainingDay = remainingDay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    let _data = {..._account, remainingDay: remainingDay};
                    setAccount(_data);
                }
                if(res.data.verification){
                    setVerification(res.data.verification);
                } else {
                    setVerification({});
                }
                if(res.data.whiteList){
                    setWhiteList(res.data.whiteList);
                }
            });
    };

    return(
        <div>
            <Box display="flex" justifyContent="start" alignItems="center" pt={1} pl={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/accounts" style={{color: '#1abc9c',
                        textDecoration: 'none'}}>
                        <Box display="flex" alignItems="center">
                            <ArrowBackIcon />
                        </Box>
                    </Link>
                </Breadcrumbs>
                <Typography variant="h6" pl={1}>
                    {t('account.information')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <Grid container spacing={1} p={1}>
                <Grid item xs={12}>
                    <Box mb={1}>
                        <Info value={account} verification={verification} whiteList={whiteList} control={true} verifyProgress={[3,6].indexOf(verification.step) > -1} onChange={handlePlanChange}/>
                    </Box>
                    <Box mb={1}>
                        <Owner value={account} relate={true} onChange={handlePlanChange} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            {generating &&
                                <Box display="flex" justifyContent="center" mt={4}>
                                    <CircularProgress size={20}/>
                                </Box>
                            }
                            {!generating && false &&
                                <Box>
                                    <PartnerInfo partner={partner} accountId={account._id}></PartnerInfo>
                                </Box>
                            }
                            {!generating && qrValue &&
                                <Box display="flex" justifyContent="center" >
                                    <QRCode name="qrcode" value={qrValue} filename={account.cif} />
                                </Box>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={1}>
                        <AccountInvoice id={account._id} />
                    </Box>
                </Grid>
            </Grid>
            }
        </div>
    )
}