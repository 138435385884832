import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Accounts from "../Dashboard/Accounts";
import Invoices from "../Dashboard/Invoices";
import './style.css';

export default function Home() {
    const { t } = useTranslation();

    useEffect(() => {
        console.log('Home');
    }, []);

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" pt={1} pl={1}>
                <Typography variant="h6">
                    {t('dashboard.title')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            <Grid container spacing={2} p={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Card elevation={1}>
                        <Accounts />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card elevation={1}>
                        <Invoices />
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}