import axios from 'axios'

axios.interceptors.request.use(async (config)=> {

    const jwtToken = localStorage.getItem('admin-token');
    if (jwtToken) {
        config.headers = { 'Authorization': `Bearer ${jwtToken}` }
    }
    return config;

});
export const httpClient = axios;