import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import VerifiedIcon from '@mui/icons-material/Verified';
import BlockIcon from '@mui/icons-material/Block';
import SendIcon from '@mui/icons-material/Send';
import {httpClient} from "../../../core/HttpClient";

export default function Verify({value, onAction}) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [result, setResult] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [reason, setReason] = React.useState('');
    const [require, setRequire] = React.useState({
        identification: false,
        verification: false,
        storeLocation: false,
    });

    const handleClose = () => {
        setResult(0);
        setOpen(false);
    };

    const confirmVerify = (value) => {
        setResult(value);
        if(value === 2){
            setReason('notClearDocument');
        } else if(value === 3){
            setReason('');
        }
        setOpen(true);
    };

    const handleVerify = (state) => {
        setLoading(true);
        let data = {...value, state: state, reason: reason, require: require};
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/verify';
        httpClient.post(url, data)
            .then(res => {
                setLoading(false);
                setOpen(false);
                onAction(res.data);
            });
    };

    const handleSelectChange = ({ target }) => {
        setReason(target.value);
    };

    const handleCheckChange = ({ target }) => {
        let _data = {...require, [target.name]: target.checked};
        setRequire(_data);
    };

    return(
        <div>
            <Box mt={2}>
                <Box>
                    <Button variant="contained"
                            size="large"
                            color="primary"
                            fullWidth={true}
                            disableElevation
                            onClick={(e) => confirmVerify(1)}
                            style={{fontWeight: 'bold'}}>
                        <span>{t('common.approve')}</span>
                    </Button>
                </Box>
                <Box mt={1}>
                    <Button variant="contained"
                            size="large"
                            color={"orange"}
                            fullWidth={true}
                            disableElevation
                            onClick={(e) => confirmVerify(2)}
                            style={{fontWeight: 'bold'}}>
                        <span>{t('account.requestMoreDocument')}</span>
                    </Button>
                </Box>
                <Box mt={1}>
                    <Button variant="contained"
                            size="large"
                            color="secondary"
                            fullWidth={true}
                            disableElevation
                            onClick={(e) => confirmVerify(3)}
                            style={{fontWeight: 'bold'}}>
                        <span>{t('common.reject')}</span>
                    </Button>
                </Box>
            </Box>
            <Dialog
                open={open}
                fullWidth
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {result === 1 && t('account.confirmVerify')}
                    {result === 2 && t('account.requireDocuments')}
                    {result === 3 && t('account.confirmReject')}
                </DialogTitle>
                <DialogContent>
                    {result === 2 &&
                    <>
                    <Box mt={1}>
                        <FormControl variant="outlined"
                                     fullWidth={true}>
                            <InputLabel id="reason-label">{t('common.reason')}</InputLabel>
                            <Select
                                native
                                labelId="reason-label"
                                label={t('common.reason')}
                                name="reason"
                                value={reason}
                                onChange={handleSelectChange}>
                                <option value="notClearDocument">{t('common.reasons.notClearDocument')}</option>
                                {/*<option value="requireDocument">{t('common.reasons.requireDocument')}</option>*/}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <FormControlLabel
                            control={
                            <Checkbox
                            checked={require.identification}
                            onChange={handleCheckChange}
                            name="identification"
                            color="primary"
                            />
                        }
                            label={t('account.requireList.identification')}
                            />
                    </Box>
                    <Box mt={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={require.verification}
                                    onChange={handleCheckChange}
                                    name="verification"
                                    color="primary"
                                />
                            }
                            label={t('account.requireList.verification')}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={require.storeLocation}
                                    onChange={handleCheckChange}
                                    name="storeLocation"
                                    color="primary"
                                />
                            }
                            label={t('account.requireList.storeLocation')}
                        />
                    </Box>
                    </>
                    }
                    {result === 3 &&
                    <Box mt={1}>
                        <FormControl variant="outlined"
                                     fullWidth={true}>
                            <InputLabel id="reason-label">{t('common.reason')}</InputLabel>
                            <Select
                                native
                                labelId="reason-label"
                                label={t('common.reason')}
                                name="reason"
                                value={reason}
                                onChange={handleSelectChange}>
                                <option value=""></option>
                                <option value="report">{t('common.reasons.report')}</option>
                                <option value="breaksPolicy">{t('common.reasons.breaksPolicy')}</option>
                                <option value="fraudMerchant">{t('common.reasons.fraudMerchant')}</option>
                            </Select>
                        </FormControl>
                    </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            color={"default"}
                            onClick={handleClose}
                            fullWidth={true}
                            size="large"
                            disabled={loading}
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                    {result === 1 &&
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={true}
                            disabled={loading}
                            startIcon={<VerifiedIcon/>}
                            onClick={(e) => handleVerify('approve')}>
                        {t('common.approve')}
                    </Button>
                    }
                    {result === 2 &&
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={true}
                            disabled={ !(require.identification || require.verification ||  require.storeLocation)  || loading}
                            startIcon={<SendIcon/>}
                            onClick={(e) => handleVerify('request')}>
                        {t('common.sendRequest')}
                    </Button>
                    }
                    {result === 3 &&
                    <Button variant="contained"
                            color="secondary"
                            size="large"
                            disableElevation
                            fullWidth={true}
                            disabled={ !reason  || loading}
                            startIcon={<BlockIcon/>}
                            onClick={(e) => handleVerify('reject')}>
                        {t('common.reject')}
                    </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}