import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import InfiniteScroll from 'react-infinite-scroll-component';
import {DateI18n} from "../../DateI18n";
import {httpClient} from "../../../core/HttpClient";
import {Badge, ListItemButton} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

let MyItemTitle = ({task, t}) => {
    return (<ListItemText
        primary={<Box>
            <Typography variant="body1">
                {t(`task.actions.${task.action}`)}
            </Typography>
            <Typography variant="body1">
                {t('task.refNo')}: {task.refNo}
            </Typography>
        </Box>
        }
        secondaryTypographyProps={{component: 'div'}}
        secondary={<Box display={"flex"} justifyContent={"space-between"}>
            <DateI18n value={task.modified} format="DD MMM YYYY HH:mm" />
            <Typography variant="body1">
                {t(`task.statues.${task.status}`)}
            </Typography>
        </Box>
        } />);
};

export default function TaskList({status}) {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [page, setPage] = useState(1);
    const [manualLoadMore, setManualLoadMore] = useState(true);

    useEffect(() => {
        console.log('TaskList');
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/tasks?p=1&s='+status;
        httpClient.get(url)
            .then(res => {
                if(res.data && res.data.length > 0){
                    setTasks(res.data);
                    if(res.data && res.data.length < 30){
                        setManualLoadMore(false);
                    }
                } else {
                    setManualLoadMore(false);
                }
                setLoading(false);
            });

    }, [status]);

    const handleClick= (task) =>{
        if(task.action === 'VERIFICATION'){
            history.push(`/accounts/${task.account._id}`);
        } else {
            history.push(`/tasks/${task._id}`);
        }
    }

    const handleFetchData = () =>{
        console.log('handleFetchData');
        let p = page;
        ++p;
        setPage(p);
        const url = process.env.REACT_APP_API_BASE_URL + '/admin/tasks?p='+p+'&s='+status;
        httpClient.get(url)
            .then(res => {
                if(res.data && res.data.length > 0){
                    setTasks(tasks.concat(res.data));
                    if(res.data && res.data.length < 30){
                        setManualLoadMore(false);
                    }
                } else {
                    setManualLoadMore(false);
                }
            });
    };

    return (
        <div>
            <InfiniteScroll
                dataLength={tasks.length} //This is important field to render the next data
                next={handleFetchData}
                hasMore={manualLoadMore}
                loader={<Box display={"flex"} justifyContent={"center"} mt={4}><CircularProgress size={20}/></Box>}
            >
                <List>
                    {tasks.map((value, index) => (
                        <ListItemButton key={`item-${status}-${index}`}
                            onClick={()=>handleClick(value)}
                        >
                            <ListItemAvatar>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        value.action === 'INDICTMENT'?
                                        <Avatar sx={{ width: 20, height: 20, border: '2px solid white' }}
                                                alt="Profile"
                                                src={(value.customer)?`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${value.customer}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                                            : <Avatar sx={{ width: 20, height: 20, border: '2px solid white' }}
                                                      alt="Profile"
                                                      src={(value.user)?`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${value.user._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                                    }
                                >
                                    <Avatar sx={{ width: 36, height: 36 }}
                                            alt="Account"
                                            variant={"square"}
                                            src={(value.account.image && value.account.image.name)?`${process.env.REACT_APP_CDN_BASE_URL}/${value.account.image.permission}/merchant/o/${value.account.image.name}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                                </Badge>

                            </ListItemAvatar>
                            <ListItem key={value._id} divider={index !== tasks.length - 1}>
                                <MyItemTitle task={value} t={t} />
                            </ListItem>
                        </ListItemButton>
                    ))}
                </List>
            </InfiniteScroll>
            {tasks.length === 0 && !loading &&
                <Box display="flex" justifyContent="center" mt={4} mb={2}>
                    {t('task.taskEmpty')}
                </Box>
            }
        </div>
    )
}