import React from 'react';
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {ListItem, ListItemButton} from "@mui/material";
import {getTextI18n} from "../../TextI18n";
import {getPhoneNumber} from "../../TextFormat";
import {useHistory} from "react-router-dom";

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/s/${value.image.name}`;
    }
    return (<Avatar
        alt={name}
        variant={"square"}
        src={imageUrl}
    />)
};

export default function TaskShop({value}) {
    const { t } = useTranslation();
    const history = useHistory();

    const handleTel = (mobile) => {
        const link = document.createElement('a');
        link.id = 'phone';
        link.href = 'tel:'+mobile;
        link.click();
    }

    const handleLinkClick= () => {
        history.push(`/accounts/${value._id}`);
    }

    return(
        <div>
            <Card variant="outlined">
                <CardHeader
                    title={t('account.title')}
                />
                {value.owner &&
                <>
                    <List sx={{paddingTop: '0px'}}>
                        <ListItem secondaryAction={value.phone &&
                            <IconButton color={"primary"} edge="end" aria-label="tel"
                                        onClick={e => handleTel(value.phone)}>
                                <Avatar sx={{background: "#1abc9c", width: 32, height: 32}}>
                                    <PhoneIcon fontSize={"small"}/>
                                </Avatar>
                            </IconButton>
                        }>
                            <ListItemButton onClick={handleLinkClick}>
                            <ListItemAvatar>
                                <MyAvatar value={value} />
                            </ListItemAvatar>
                            <ListItemText primary={value.name.i18n.th}
                                          secondary={getPhoneNumber(value.phone)}
                            />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </>
                }
            </Card>
        </div>
    )
}